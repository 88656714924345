import axios from 'axios';

import type { THttpClientOptions } from '../types/api';

export function createHttpClient({ baseURL, timeout }: THttpClientOptions) {
  return axios.create({
    baseURL,
    timeout,
  });
}

const httpClientOptions = {
  baseURL: process.env.REACT_APP_API_BASE_URL || 'API_BASE_URL_NOT_SPECIFIED',
  timeout: parseInt(process.env.REACT_APP_API_TIMEOUT || '5000')
}

export const httpClient = createHttpClient(httpClientOptions);
