import React, { ChangeEvent } from 'react';
import { Title } from 'react-admin';
import { Card, Table, Thead, Tr, Td, Tbody, Checkbox } from '@chakra-ui/react';

import type { TRules } from '../../../types';

type TRuleListViewProps = {
  isLoading: boolean;
  onRuleCheckClick: (event: ChangeEvent<HTMLInputElement>) => void;
  rules: TRules;
}

export const RuleBlockView: React.FC<TRuleListViewProps> = props => {
  const { isLoading, onRuleCheckClick, rules } = props;

  if (isLoading) {
    return <div>Загрузка...</div>;
  }

  return (
    <>
      <Title title="Проверка документов"/>
      <Card>
        <Table sx={{ padding: 2 }}>
          <Thead>
            <Tr>
              <Td>ID</Td>
              <Td>Правило</Td>
              <Td>Описание</Td>
              <Td>Выбрать</Td>
            </Tr>
          </Thead>
          <Tbody>
            {rules.map(({ id, displayName, description, isChecked }) => (
              <Tr key={id}>
                <Td>{id}</Td>
                <Td>{displayName}</Td>
                <Td>{description}</Td>
                <Td>
                  <Checkbox onChange={onRuleCheckClick} checked={isChecked} value={id}/>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Card>
    </>
  );
};
