import { Survey } from 'survey-react-ui';
import { styled } from '@linaria/react';
import { usePresenterStore } from './store';
import { observer } from 'mobx-react';
import 'survey-core/defaultV2.min.css';
import 'survey-core/i18n/russian';
import './survey-presenter.css';

export const SurveyPresenter = observer(() => {
    const store = usePresenterStore();

    return store.model && (
        <ScrollEnabler>
            <Survey
                id="survey"
                model={store.model} />
        </ScrollEnabler>
    );
});

const ScrollEnabler = styled.div`
    height: 100%;
    width: 100%;
    position: fixed;
`;
