import { Link as ReactRouterLink, useNavigate } from 'react-router-dom'
import { Flex, Icon, Link, FlexProps } from '@chakra-ui/react';
import { IconType } from 'react-icons';

interface INavItemProps extends FlexProps {
    icon: IconType;
    href: string;
    children: string;
    closeSidebar: () => void;
}

export const NavItem = ({ icon, href, closeSidebar, children, ...rest }: INavItemProps) => {
    const navigage = useNavigate();

    function handleNavItemClick() {
        closeSidebar();
        navigage(href);
    }
    
    return (
        <Link 
            onClick={handleNavItemClick}
            to={href} 
            as={ReactRouterLink} 
            style={{ textDecoration: 'none' }} 
            _focus={{ boxShadow: 'none' }}>
            <Flex
                align="center"
                p="4"
                mx="4"
                borderRadius="lg"
                role="group"
                cursor="pointer"
                _hover={{
                    bg: 'gray.100',
                    color: 'black',
                    transition: '0.3s',
                }}
                {...rest}>
                {icon && (
                    <Icon
                        mr="4"
                        fontSize="16"
                        _groupHover={{
                            color: 'black',
                        }}
                        as={icon}
                    />
                )}
                {children}
            </Flex>
        </Link>
    );
};
