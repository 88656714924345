import { useState } from 'react';

export type TValidationErrors = Record<string, string | undefined>;

export function useValidationErrors() {
    const [validationErrors, setValidationErrors] = useState<TValidationErrors>({});

    return {
        validationErrors,
        setValidationErrors,
    }
}
