import type { AxiosResponse } from 'axios';
import type { Credentials, LoginSuccessData } from '../types/auth';
import { httpClient } from './http-client';

export class AuthApi {
  static login(creds: Credentials): Promise<AxiosResponse<LoginSuccessData, unknown>> {
    return httpClient.post('/auth/login', creds);
  }

  // Не реализован
  // public logout(creds: Credentials): Promise<AxiosResponse> {
  //   return this.httpClient.post('/auth/logout', creds);
  // }
}
