import React, { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { IoDocumentsOutline } from 'react-icons/io5';
import { ArrowUpIcon, DeleteIcon, DownloadIcon } from '@chakra-ui/icons'
import { Stack, Menu, MenuList, MenuItem, useDisclosure, useToast, useMediaQuery } from '@chakra-ui/react'
import { SidebarButton } from '../side-instruments';
import { QuizzesTableModal } from './quizzes-table-modal';
import { QuizSaveButton } from './quiz-save-button';
import { useStore } from '../../store';
import { Api } from '../../../../api';
import { quizSavedToast } from '../toasts';
import { ToTestButton } from './to-test-button';
import { QuizOptionsButton } from './quiz-options-button';

type ControlsTopPanelProps = {
    upload: (event) => void;
    download: (event: React.MouseEvent) => void;
    clear: () => void;
}

export const ControlsTopPanel: React.FC<ControlsTopPanelProps> = ({ clear, upload, download }) => {
    const navigate = useNavigate();
    const toast = useToast();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [isMobile] = useMediaQuery('(max-width: 767px)');
    const store = useStore();
    const hiddenInputRef = useRef<HTMLInputElement>(null);

    function handleUploadClick() {
        if (hiddenInputRef.current) {
            hiddenInputRef.current.click()
        }
    }

    function handleSwitchToTest() {
        if (store.quizId) {
            navigate(`/quiz-test/${store.quizId}`)
        } else {
            onOpen();
        }
    }

    function handleFileUploaded({ target: { files } }: React.ChangeEvent<HTMLInputElement>) {
        if (files) {
            upload(files[0]);
        }
    }

    function handleSaveClick() {
        if (store.quizId) {
            const quizContents = store.prepareForSave();
            Api.saveQuizContents({ id: store.quizId, ...quizContents });
            toast(quizSavedToast);
        } else {
            onOpen();
        }
    }

    return (
        <Stack
            order={1}
            direction="row"
            position="absolute"
            height="fit-content"
            width="100%"
            align="start"
            padding="24px"
            pointerEvents="auto"
            display="flex"
            flexWrap="wrap"
            zIndex={2}
            justifyContent="space-between">
            <Stack direction="row">
                <QuizSaveButton onSave={handleSaveClick} />
                <Menu>
                    <QuizOptionsButton isMobile={isMobile}/>
                    <MenuList>
                        <MenuItem onClick={onOpen} icon={<IoDocumentsOutline />}>Мои опросники</MenuItem>
                        <MenuItem onClick={handleUploadClick} icon={<ArrowUpIcon />}>Импорт</MenuItem>
                        <MenuItem onClick={download} icon={<DownloadIcon />}>Скачать</MenuItem>
                        <MenuItem onClick={clear} icon={<DeleteIcon />}>Очистить</MenuItem>
                    </MenuList>
                </Menu>
                <ToTestButton switchToTest={handleSwitchToTest} isMobile={isMobile} />
            </Stack>
            <SidebarButton />
            <input
                type="file"
                ref={hiddenInputRef}
                accept=".json"
                onChange={handleFileUploaded}
                hidden />
            <QuizzesTableModal
                onClose={onClose}
                isOpen={isOpen} />
        </Stack>
    );
}
