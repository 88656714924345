import { useContext, useState, useRef } from 'react';
import { InspectionStore, InspectionStoreContext } from '../store';

export function useControls() {
  const [isResultsVisible, setIsResultsVisible] = useState(false);
  const store = useContext<InspectionStore>(InspectionStoreContext);
  const resultsTableRef = useRef<HTMLDivElement | null>(null);

  return {
    onSubmitClick: async () => {
      const isSuccess = await store.submitDocumentsForInspection();
      setIsResultsVisible(isSuccess);
    },
    onClearClick: async () => store.clearInspectionResults(),
    hideResults: () => setIsResultsVisible(false),
    isProcessing: store.isInspectionResultLoading,
    isResultsVisible,
    rules: store.rules,
    results: store.ruleMatchResults,
    mainDocEnts: store.mainDocEnts,
    supplDocEnts: store.supplDocEnts,
    resultsTableRef,
    docsCount: store.documentCards.length
  }
}
