import { Route, Routes as RouterRoutes, Navigate, useLocation } from 'react-router-dom';
import { Main } from './pages/main/main';
import { Inspection } from './pages/inspection';
import { Login } from './pages/login/login';
import { useAuthContext, RequireAuth } from './auth';
import { QuizCreator } from './pages/quiz-creator';
import { QuizPresenter } from './pages/quiz-presenter';

export const Routes = () => {
  const { userName } = useAuthContext();
  const location = useLocation();
  const pathName = location.state?.from || '/'

  return (
    <RouterRoutes>
      <Route path="/" element={<Main />} />
      <Route path="/login" element={<Login />} />
      {userName ? <Route path='/login' element={<Navigate to={pathName} />} /> : <Route path='/login' element={<Login />} />}
      <Route path="/inspection" element={<RequireAuth><Inspection /></RequireAuth>} />
      <Route path='/quiz-creator/:quizId' element={<RequireAuth><QuizCreator /></RequireAuth>} />
      <Route path='/quiz-creator' element={<RequireAuth><QuizCreator /></RequireAuth>} />
      <Route path='/quiz-test/:quizId' element={<RequireAuth><QuizPresenter isDevMode /></RequireAuth>} />
      <Route path='/quiz/:quizHash' element={<QuizPresenter />} />
    </RouterRoutes>
  );
}

