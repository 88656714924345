import React from 'react';
import { styled } from '@linaria/react';

import type { TEntityCategory } from '../../../../types';
import { DocEntities } from './components';

type TNamedEntities = {
  mainDocEnts: TEntityCategory[];
  supplDocEnts: TEntityCategory[];
  docsCount: number;
}

export const NamedEntities: React.FC<TNamedEntities> = ({ mainDocEnts, supplDocEnts, docsCount }) => {
  return (
    <DocumentEnitites>
      <DocEntities docRoleName="Основной документ" entities={mainDocEnts} />
      {docsCount > 1 && <DocEntities docRoleName="Дополнительные документы" entities={supplDocEnts} />}
    </DocumentEnitites>
  );
}

NamedEntities.displayName = 'NamedEntities';

const DocumentEnitites = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
  gap: var(--space-200);
`;
