import React, { ReactNode } from "react"
import { Navigate, useLocation } from "react-router-dom";

import { useAuthContext } from "./auth-provider"

export const RequireAuth: React.FC<{ children: ReactNode }> = ({ children }) => {
    const auth = useAuthContext();
    const location = useLocation();

    if (!auth || !auth.userName || !auth.accessToken) {
        return <Navigate to='/login' state={{ from: location.pathname }} />
    }
    return <>{children}</>;
}
