import { Box, IconButton } from '@mui/material';
import { MdContentCopy } from 'react-icons/md';

export const LinkCell = ({ linkHash }: { linkHash: unknown }) => {
    if (!linkHash) {
        return null;
    }

    const link = `${window.origin}/quiz/${linkHash}`;
    
    const handleClick = () => {
        navigator.clipboard.writeText(link);
    }

    return (
        <Box width="100%" display="flex" flex="row" alignItems="center">
            <IconButton onClick={handleClick}>
                <MdContentCopy />
            </IconButton>
            <Box width="100%">{link}</Box>
        </Box>
    );
};