import { styled } from '@linaria/react';
import { QuestionAccordion, QuestionDescription, QuestionHeader, QuestionTag, questionClassName } from '../common';
import { AnswerControls } from './answer-controls';
import { useMultrivarQuestion } from './hooks';
import { css } from '@linaria/core';
import { MultivarQuestionDefaults } from '../../../../../types';
import { observer } from 'mobx-react';

type MultivarQuestionProps = {
    id: number;
    connRender: () => void;
    defaults?: MultivarQuestionDefaults;
}

export const MultivarQuestion: React.FC<MultivarQuestionProps> = observer(({ id, connRender, defaults }) => {
    const {
        answers, title, description, tag, isTagVisible, addAnswer, removeAnswer, 
        updateAnswerText, updateTitle, updateDescription, updateTag
    } = useMultrivarQuestion(id, connRender, defaults);

    return (
        <>
            <QuestionHeader value={title} onChange={updateTitle} />
            <div className={questionClassName}>
                <QuestionAccordion className={accordionClassName} buttonText="Описание вопроса">
                    <QuestionDescription value={description} onChange={updateDescription} />
                </QuestionAccordion>
                {answers.map((answer, i) => (
                    <Answer key={answer.id}>
                        <input
                            className={answerInputClassName}
                            type="text"
                            onChange={(event) => updateAnswerText(event, answer.id)}
                            key={answer.id}
                            value={answer.text}
                            placeholder={` Ответ ${i + 1}`}
                            df-db-dbname="true" />
                        <AnswerControls
                            answers={answers}
                            addAnswer={addAnswer}
                            removeAnswer={removeAnswer}
                            index={i} />
                    </Answer>
                ))}
                <QuestionTag isVisible={isTagVisible} value={tag} onChange={updateTag}/>
            </div>
        </>
    );
});

const Answer = styled.div`
    display: flex;
    flex-direction: row;
    gap: 8px;
`;

const answerInputClassName = css`
    width: 100%!important;
    margin-bottom: 8px;
`;

const accordionClassName = css`
    padding-bottom: 8px;
`;