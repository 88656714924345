import { useRef, useState } from 'react';
import { HStack, Input, Text, Button, IconButton } from '@chakra-ui/react';
import { useStore } from '../../../store';
import { Api } from '../../../../../api';
import { docGenerator } from '../../../../quiz-presenter/doc-generator';
import { DeleteIcon, DownloadIcon } from '@chakra-ui/icons';
import { FaRegSave } from 'react-icons/fa';

type TFileInputProps = {
    questionId: number;
    defaultFileName: string;
    defaultFileHash: string;
}

export const FileInput = ({ defaultFileName, questionId, defaultFileHash }: TFileInputProps) => {
    const fileInput = useRef<HTMLInputElement>(null);
    const [fileName, setFileName] = useState(defaultFileName);
    const [fileHash, setFileHash] = useState(defaultFileHash);
    const [file, setFile] = useState<File | null>(null);
    const store = useStore();

    function handleFileClick() {
        if (fileInput.current) {
            fileInput.current.click();
        }
    }

    function handleFileChange({ target: { files } }: React.ChangeEvent<HTMLInputElement>) {
        if (files) {
            const file = files[0];
            setFile(file);
            setFileName(file.name);
        }
    }

    async function handleFileSave() {
        if (file) {
            const fileHash = await Api.uploadFile(file);
            setFileHash(fileHash);
            store.updateNodeData(questionId, { fileHash, fileName: file.name });
            const quizContents = store.prepareForSave();
            await Api.saveQuizContents({ id: store.quizId as number, ...quizContents });
            setFile(null);
        }
    }

    async function handleFileDelete() {
        const err = await Api.deleteFile(fileHash);
        if (err?.code === 404) {
            console.error("Опросник для удаления не найден");
        }

        store.updateNodeData(questionId, { fileHash: null, fileName: null });
        unpickFile();
    }

    function unpickFile() {
        setFile(null);
        setFileHash('');
        setFileName('');
    }

    async function handleFileDownload() {
        const buffer = await Api.downloadFile(fileHash);
        const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });
        docGenerator.download(blob, fileName);
    }

    const isFilePicked = fileName.length > 0;
    const isFileUploaded = fileHash.length > 0;

    let controls = [<Button onClick={handleFileClick} colorScheme="blue">Выбрать файл</Button>];
    if (isFileUploaded) {
        controls = [
            <IconButton width={20} icon={<DeleteIcon boxSize={20} />} onClick={handleFileDelete} aria-label="remove" />,
            <IconButton width={20} icon={<DownloadIcon boxSize={20} />} onClick={handleFileDownload} aria-label="download" />
        ];
    } else if (isFilePicked) {
        controls = [
            <IconButton width={20} icon={<DeleteIcon boxSize={20} />} onClick={unpickFile} aria-label="remove" />,
            <IconButton width={20} icon={<FaRegSave size={20} />} onClick={handleFileSave} aria-label="save" />
        ];
    }

    return (
        <HStack padding={8} gap={8}>
            <Text>{fileName}</Text>,
            <HStack gap={4}>
                {controls}
            </HStack>
            <Input
                ref={fileInput}
                type="file"
                accept=".docx"
                display="none"
                onChange={handleFileChange} />
        </HStack>
    );
}
