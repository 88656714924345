import type { CreatorNode } from './types/creator-types';
import type { DrawflowNode } from 'drawflow';

/* Сортировка вопросов по их глубине в дереве решений для 
порядка вопросов в presenter-e, незавасимого от порядка создания нод вопросов в creator-е */

export function sortCreatorNodesByDepthLevel(creatorNodes: CreatorNode[]) {
    const root = getRootNode(creatorNodes);
    if (!root) {
        return;
    }

    const depthLevels = getDepthLevels(creatorNodes, root);

    creatorNodes.forEach(node => node.depthLevel = depthLevels[node.id]);
    creatorNodes.sort((a, b) => a.depthLevel - b.depthLevel);
}

function getRootNode(creatorNodes: CreatorNode[]) {
    return creatorNodes.find(({ data }) => data.isStart);
}

function getNodeDict(creatorNodes: CreatorNode[]): NodeDict {
    const nodeDict: NodeDict = {};
    creatorNodes.forEach(node => nodeDict[node.id] = node);
    return nodeDict;
}

function getDepthLevels(creatorNodes: CreatorNode[], root: CreatorNode): DepthLevels {
    const nodeIdMap = getNodeDict(creatorNodes);
    const rootNodeId = String(root.id);
    const depthLevels: DepthLevels = { [rootNodeId]: 0 };

    updateDepthLevels(nodeIdMap, root, depthLevels);

    return depthLevels;
}

function updateDepthLevels(nodes: NodeDict, root: CreatorNode, depthLevels: DepthLevels) {
    const outputs: DrawflowNode['outputs'] = Object.values(root.outputs);

    outputs.forEach(({ connections }) => {
        if (connections.length > 0) {
            const childNodeId = connections[0].node;
            depthLevels[childNodeId] = depthLevels[root.id] + 1;
            updateDepthLevels(nodes, nodes[childNodeId], depthLevels);
        }
    });
}

type NodeDict = Record<string, CreatorNode>;
type DepthLevels = Record<string, number>;
