import { ThemeProvider } from 'react-admin';
import { 
    Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody,
} from '@chakra-ui/react';
import { QuizzesTable } from './quizzes-table';

type QuizzesTableModalProps = {
    isOpen: boolean;
    onClose: () => void;
}

export const QuizzesTableModal = ({ isOpen, onClose }: QuizzesTableModalProps) => {
    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            isCentered
        >
            <ModalOverlay/>
            <ModalContent maxWidth={1200}>
                <ModalHeader borderBottom={0}>Управление опросниками</ModalHeader>
                <ModalCloseButton />
                <ModalBody pb={6}>
                    <ThemeProvider>
                        <QuizzesTable closeModal={onClose}/>
                    </ThemeProvider>
                </ModalBody>
            </ModalContent>
        </Modal>
    )
}
