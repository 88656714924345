import { useState, useEffect } from 'react'
import type { Answers, MultivarQuestionDefaults } from '../../../../../types';
import { useStore } from '../../../store';

export const useMultrivarQuestion = (questionId: number, connRerender: () => void, defaults?: MultivarQuestionDefaults) => {
    let defaultAnswers = onlyOneAnswer;
    let defaultTitle = '';
    let defaultDescription = '';
    let defaultTag = `tag_${questionId}`;

    if (defaults) {
        defaultAnswers = defaults.answers || onlyOneAnswer;
        defaultTitle = defaults.title;
        defaultDescription = defaults.description;
        defaultTag = defaults.tag;
    }

    const [answers, setAnswers] = useState<Answers>(defaultAnswers);
    const [title, setTitle] = useState(defaultTitle);
    const [description, setDescription] = useState(defaultDescription);
    const [tag, setTag] = useState(defaultTag);
    const store = useStore();
    useEffect(connRerender, [store.isTagsVisible]);

    function addAnswer() {
        setAnswers(prevAnswers => ([
            ...prevAnswers,
            { id: getIdForNewAnswer(prevAnswers), text: '' }
        ]));
        store.editor.addNodeOutput(questionId);
    }

    function removeAnswer() {
        const lastAnswerIndex = answers.length - 1;
        if (lastAnswerIndex > 0) {
            setAnswers(prevData => prevData.slice(0, -1));
            store.editor.removeNodeOutput(questionId, `output_${lastAnswerIndex}`);
        }
    }

    function updateTitle({ target: { value: title } }: React.ChangeEvent<HTMLInputElement>) {
        setTitle(title);
        store.updateNodeData(questionId, { title });
    }

    function updateDescription({ target: { value: description } }: React.ChangeEvent<HTMLTextAreaElement>) {
        setDescription(description);
        store.updateNodeData(questionId, { description });
    }

    function updateTag({ target: { value: tag } }: React.ChangeEvent<HTMLInputElement>) {
        setTag(tag);
        store.updateNodeData(questionId, { tag });
    }

    function updateAnswerText(e: React.ChangeEvent<HTMLInputElement>, answerId: number) {
        setAnswers(prevAnswers => {
            const answers = updateTextByAnswerId(prevAnswers, answerId, e.target.value);
            store.updateNodeData(questionId, { answers });
            return answers;
        });
    }

    return {
        title,
        description,
        tag,
        answers,
        isTagVisible: store.isTagsVisible,
        updateTitle,
        updateDescription,
        updateTag,
        updateAnswerText,
        addAnswer,
        removeAnswer,
    }
}

function updateTextByAnswerId(answers: Answers, answerId: number, newText: string) {
    return answers.map(({ id, text }) => {
        const updatedAnser = { id, text };
        if (id == answerId) {
            updatedAnser.text = newText;
        }
        return updatedAnser;
    });
}

function getIdForNewAnswer(answers: Answers) {
    let newAnswerId = 0;

    const lastAnswerIndex = answers.length - 1;

    if (lastAnswerIndex > -1) {
        newAnswerId = answers[lastAnswerIndex].id + 1;
    }

    return newAnswerId;
}

const onlyOneAnswer: Answers = [{ id: 0, text: '' }];
