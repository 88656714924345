import React from 'react';

import { ControlsBlockView } from './controls-block.view';
import { useControls } from './controls-block.hooks';
import { observer } from 'mobx-react';

export const ControlsBlock: React.FC = observer(() => {
  const {
    onSubmitClick, onClearClick, hideResults, isResultsVisible, isProcessing, rules, results, resultsTableRef,
    mainDocEnts, supplDocEnts, docsCount
  } = useControls();

  return (
    <ControlsBlockView
      onClearClick={onClearClick}
      onSubmitClick={onSubmitClick}
      hideResults={hideResults}
      isResultsVisible={isResultsVisible}
      isLoading={isProcessing}
      rules={rules}
      mainDocEnts={mainDocEnts}
      supplDocEnts={supplDocEnts}
      results={results}
      resultsTableRef={resultsTableRef}
      docsCount={docsCount}
    />
  );
})

ControlsBlock.displayName = 'ControlsBlock';
