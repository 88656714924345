import React from 'react';
import { Form } from 'react-admin';
import {
  Card,
  CardContent,
  Chip,
  Stack,
  Typography
} from '@mui/material';
import { css } from '@linaria/core';

import { styled } from '@linaria/react';

import { getClassSecriotionByLabel } from './entities-description';
import type { TEntityCategory } from '../../../../../types';

type TDocEntities = {
  docRoleName: string;
  entities: TEntityCategory[]
}

export const DocEntities: React.FC<TDocEntities> = ({ docRoleName, entities }) => {
  return (
    <Form className={docEntitiesStyle}>
      <CardContent sx={cardContentStyle}>
        <Typography variant="h6" component="div">
          {docRoleName}
        </Typography>
        {entities.length === 0 ?
          <div>Сущности не найдены</div> :
          entities.map(ent => <EntityCard label={ent.label} content={ent.content} />)}
      </CardContent>
    </Form>
  )
}

const EntityCard: React.FC<TEntityCategory> = ({ label, content }) => (
  <Card variant='outlined'>
    <CardContent sx={entityCardContentStyle}>
      <CardHeader>
        <Typography sx={{ fontWeight: 'bold' }} component="div">
          {getClassSecriotionByLabel(label)}
        </Typography>
      </CardHeader>
      <Stack direction="row" spacing={1} sx={entityCardChipsStackStyle}>
        {content.map(({ text }) => <Chip sx={chipStyle} label={text} variant="outlined" />)}
      </Stack>
    </CardContent>
  </Card>
);

DocEntities.displayName = 'DocEntities';

const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const docEntitiesStyle = css`
  max-width: 500px;
`

const entityCardContentStyle = {
  display: 'flex',
  flexDirection: 'column',
  gap: 'var(--space-100)',
  width: '100%'
};

const entityCardChipsStackStyle = {
  flexWrap: 'wrap',
  gap: 'var(--space-100)',
}

const chipStyle = {
  marginLeft: '0px!important',
}

const cardContentStyle = {
  display: 'flex',
  flexDirection: 'column',
  gap: 1,
  maxWidth: 500
};

