import React from 'react';
import { observer } from 'mobx-react';
import { styled } from '@linaria/react';

import { DocsBlock } from './docs-block';
import { ControlsBlock } from './controls-block';
import { RuleBlock } from './rules-block';

export const Inspection: React.FC = observer(() => {
  return (
    <InspectionViewBlocks>
      <DocsBlock />
      <ControlsBlock />
      <RuleBlock />
    </InspectionViewBlocks>
  );
});

Inspection.displayName = 'Inspection';

const InspectionViewBlocks = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: var(--space-200);
`;
