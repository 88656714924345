import React, { createContext, useContext, ReactNode } from 'react';

import { useAuth } from './use-auth';
import type { TAuthContext } from '../types/auth';

const defaultAuthContextValue = {
       accessToken: null,
       userName: null,
       signIn: () => Promise.resolve(),
       signUp: () => Promise.resolve(),
       signOut: () => { },
}

const AuthContext = createContext<TAuthContext>(defaultAuthContextValue);

type TAuthProviderProps = {
       children: ReactNode;
}

export const AuthProvider: React.FC<TAuthProviderProps> = ({ children }) => {
       const auth = useAuth();

       return <AuthContext.Provider value={auth}>
              {children}
       </AuthContext.Provider>
}

export const useAuthContext = () => useContext(AuthContext)
