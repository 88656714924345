import Drawflow, { type DrawflowExport, type DrawflowNode } from 'drawflow';
import { makeObservable, observable, action, configure } from 'mobx';
import { createRoot } from 'react-dom/client';
import { CreatorQuestionTypeEnum } from '../../../constants';
import { MultivarQuestion, TextInputQuestion, Conclusion } from '../components';
import { getMultivarQuestionDefaults, getConclusionDefaults, getTextIpuntDefaults, connRenderPlaceholder, defaultCreatorData, nodeDefaults } from './defaults';
import type { IGenericQuestion, Positions, QuestionDefaults } from '../../../types';
import { CreatorStoreProvider } from './creator-store-provider';
import { PresenterTheme, fromCreatorNodesToPresenterQuestions, fromFlowNodesToCreatorNodes, sortCreatorNodesByDepthLevel } from '../../../converters';
import { TQuizJSONObject } from '../components/controls-top-panel/quizzes-table-modal/types';
import { Api } from '../../../api';

configure({ safeDescriptors: false });

export class CreatorStore {
  editor: Drawflow | null = null;
  quizId: number | undefined = undefined;
  presenterTheme: PresenterTheme = {};
  isTagsVisible: boolean = false;

  constructor(quizId?: number | undefined) {
    makeObservable(this, {
      editor: observable,
      quizId: observable,
      presenterTheme: observable,
      init: action,
      isTagsVisible: observable,
      setQuizId: action,
      setPresenterTheme: action,
      setIsTagsVisible: action,
    });

    this.init(quizId);
  }

  init(quizId: number | undefined) {
    this.quizId = quizId;
    const root = this.getRootElement();
    if (root) {
      this.editor = new Drawflow(root);
      this.editor.start();
      this.initQuestionsState();
    }
  }

  addQuestion(type: string, pos: Positions) {
    let nodeId: number;
    switch (type) {
      case CreatorQuestionTypeEnum.MULTIVAR_BUTTON:
        nodeId = this.createNode(CreatorQuestionTypeEnum.MULTIVAR_BUTTON, 1, 1, pos, 'multivar-question')
        this.updateNodeData(nodeId, getMultivarQuestionDefaults(nodeId));
        this.renderQuestion(nodeId, MultivarQuestion);
        break;
      case CreatorQuestionTypeEnum.TEXT_INPUT:
        nodeId = this.createNode(CreatorQuestionTypeEnum.TEXT_INPUT, 1, 1, pos, 'text-input')
        this.updateNodeData(nodeId, getTextIpuntDefaults(nodeId));
        this.renderQuestion(nodeId, TextInputQuestion);
        break;
      default:
        nodeId = this.createNode(CreatorQuestionTypeEnum.CONCLUSION, 1, 0, pos, 'conclusion')
        this.updateNodeData(nodeId, getConclusionDefaults(nodeId));
        this.renderQuestion(nodeId, Conclusion);
    }
  }

  toggleTagsVisibility() {
    this.setIsTagsVisible(!this.isTagsVisible);
  }

  setIsTagsVisible(isVisible: boolean) {
    this.isTagsVisible = isVisible;
  }

  private createNode(questionType: string, inputs: number, outputs: number, pos: Positions, className: string) {
    return this.editor?.addNode(questionType,
      inputs, outputs, pos.xPos, pos.yPos,
      className, ...nodeDefaults) as number;
  }

  async initQuestionsState() {
    if (this.quizId) {
      const { creatorContent, presenterTheme } = await Api.getQuizForCreator(this.quizId);
      this.restoreCreatorState(creatorContent);
      this.setPresenterTheme(presenterTheme);
    } else {
      this.createCreatorState();
    }
  }

  prepareForSave() {
    const creatorContent = this.editor.export();
    const creatorNodes = fromFlowNodesToCreatorNodes(creatorContent.drawflow.Home.data as unknown as TQuizJSONObject);
    sortCreatorNodesByDepthLevel(creatorNodes);
    const presenterQuestions = fromCreatorNodesToPresenterQuestions(creatorNodes);
    const presenterContent = { questions: presenterQuestions } as unknown as TQuizJSONObject;

    return {
      creatorContent,
      presenterContent,
      presenterTheme: this.presenterTheme,
    }
  }

  restoreCreatorState(quizData: DrawflowExport) {
    if (this.editor) {
      this.editor.import(quizData);
      this.renderRestoredQuestions();
    }
  }

  createCreatorState() {
    this.editor?.import(defaultCreatorData);
    this.renderDefaultQuestion();
  }

  renderRestoredQuestions() {
    const creatorNodes = this.getCreatorNodes();
    creatorNodes.forEach(({ data }) => {
      switch (data.type) {
        case CreatorQuestionTypeEnum.MULTIVAR_BUTTON:
          this.renderQuestion(data.id, MultivarQuestion, data);
          break;
        case CreatorQuestionTypeEnum.TEXT_INPUT:
          this.renderQuestion(data.id, TextInputQuestion, data);
          break;
        default:
          this.renderQuestion(data.id, Conclusion, data);
      }
    });
  }

  updateNodeData(questionId: number, question: Partial<IGenericQuestion>) {
    const nodeData = this.getNodeData(questionId).data;
    this.editor.updateNodeDataFromId(questionId, { ...nodeData, ...question });
  }

  getNodeData(questionId: number): DrawflowNode {
    return this.editor.getNodeFromId(questionId);
  }

  setQuizId(quizId: number) {
    this.quizId = quizId;
  }

  setPresenterTheme(presenterTheme: PresenterTheme) {
    this.presenterTheme = presenterTheme;
  }

  private renderDefaultQuestion() {
    const node = this.getStartNodeElement();
    if (node) {
      createRoot(node).render(
        <CreatorStoreProvider store={creatorStore}>
          <MultivarQuestion id={1} connRender={connRenderPlaceholder} />
        </CreatorStoreProvider>
      );
    }
  }

  private renderQuestion(nodeId: number, Component: TQuestionComponent, defaults?: QuestionDefaults) {
    const node = this.getNodeElement(nodeId);
    if (node) {
      createRoot(node).render(
        <CreatorStoreProvider store={creatorStore}>
          <Component id={nodeId} connRender={this.getConnRenderFunc(nodeId)} defaults={defaults} />
        </CreatorStoreProvider>
      );
    }
  }

  private getCreatorNodes(): DrawflowNode[] {
    if (this.editor) {
      const flowNodes = this.editor.export().drawflow.Home.data;
      return Object.values(flowNodes);
    }
    return [];
  }

  private getRootElement() {
    return document.getElementById('drawflow')
  }

  private getStartNodeElement() {
    return document.querySelector('#node-1 .drawflow_content_node');
  }

  private getNodeElement(nodeId: number) {
    return document.querySelector(`#node-${nodeId} .drawflow_content_node`);
  }

  private getConnRenderFunc(nodeId: number) {
    return () => this.editor?.updateConnectionNodes(`node-${nodeId}`);
  }
}

type TQuestionComponent =
  typeof MultivarQuestion |
  typeof TextInputQuestion |
  typeof Conclusion;

export const creatorStore = new CreatorStore();
