import { ChangeEvent } from 'react';

type QuestionDescriptionProps = {
    value: string;
    placeholder?: string;
    onChange: (e: ChangeEvent<HTMLTextAreaElement>) => void;
};

export const QuestionDescription: React.FC<QuestionDescriptionProps> = ({ value, placeholder = " Текст...", onChange }) => (
    <textarea
        onChange={onChange}
        value={value}
        placeholder={placeholder}
        style={{ height: 'auto', width: '100%' }}
        cols={60}
        rows={2}
        df-db-dbname="true" />
);
