
import { useEffect } from 'react';
import { useStore } from '../../../../store';

export function useCreateTrigger(startCreating: () => void) {
    const store = useStore();
    
    useEffect(() => {
        if (!store.quizId) {
            startCreating();
        }
    }, []);
}
