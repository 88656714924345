import { QuestionDescription, QuestionHeader, questionClassName } from '../common';
import { useConslusion } from './hooks';
import type { ConclusionDefaults } from '../../../../../types';
import { FileInput } from './file-input';

type ConclusionProps = {
    id: number;
    connRender: () => void;
    defaults?: ConclusionDefaults;
};

export const Conclusion: React.FC<ConclusionProps> = ({ id, defaults, connRender }) => {
    const { title, description, updateTitle, updateDescription } = useConslusion(id, connRender, defaults);

    return (
        <>
            <QuestionHeader value={title} onChange={updateTitle} />
            <div className={questionClassName}>
                <QuestionDescription value={description} onChange={updateDescription} />
                <FileInput
                    questionId={id}
                    defaultFileName={defaults?.fileName || ''}
                    defaultFileHash={defaults?.fileHash || ''} />
            </div>
        </>
    );
}
