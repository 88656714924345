import { useState, useEffect } from 'react';

export const useLocalStorage = (key: string): [string | null, React.Dispatch<React.SetStateAction<string | null>>] => {
  const [value, setValue] = useState<string|null>(() => {
    return localStorage.getItem(key);
  });

  useEffect(() => {
    if (value) {
      localStorage.setItem(key, value);
    } else {
      localStorage.removeItem(key);
    }
  }, [key, value]);

  return [value, setValue];
}
