import React from "react";
import { css } from "@linaria/core";
import { IconButton } from '@chakra-ui/react';
import { AddIcon, MinusIcon, ArrowLeftIcon } from '@chakra-ui/icons';

type ZoomButtonsProps = {
    zoomOut: () => void;
    zoomReset: () => void;
    zoomIn: () => void;
}

export const ZoomButtons: React.FC<ZoomButtonsProps> = ({ zoomOut, zoomIn, zoomReset }) => (
    <div className={className}>
        <IconButton
            onClick={zoomIn}
            icon={<AddIcon />}
            colorScheme="gray"
            isRound={true}
            aria-label="zoomIn" />
        <IconButton
            onClick={zoomOut}
            icon={<MinusIcon />}
            colorScheme="gray"
            isRound={true}
            aria-label="zoomOut" />
        <IconButton
            onClick={zoomReset}
            icon={<ArrowLeftIcon />}
            colorScheme="gray"
            isRound={true}
            aria-label="zoomReset" />
    </div>
);

const className = css`
    display: flex;
    flex-direction: row;
    gap: 8px;
    pointer-events: auto;
`; 
