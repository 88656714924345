import { Stack } from '@chakra-ui/react';
import { ZoomButtons } from './zoom-buttons';

type ControlsBottomPanelProps = {
    isLocked: boolean;
    toggleLock: () => void;
    zoomIn: () => void;
    zoomOut: () => void;
    zoomReset: () => void;
}

export const ControlsBottomPanel: React.FC<ControlsBottomPanelProps> = ({
    isLocked, toggleLock, zoomIn, zoomOut, zoomReset
}) => (
    <Stack
        width="fit-content"
        order={3}
        direction="row"
        padding="0px 0px 24px 24px"
        position="relative"
        zIndex={2}
        pointerEvents="auto">
        {/* <LockButton toggleBlock={toggleLock} isLocked={isLocked} /> */}
        <ZoomButtons zoomIn={zoomIn} zoomOut={zoomOut} zoomReset={zoomReset} />
    </Stack>
);
