import React, { ChangeEvent } from 'react';
import { FormControlLabel, Switch } from '@mui/material';

type TFormSwitchButtonProps = {
  text: string;
  isChecked: boolean;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

export const FormSwitchButton: React.FC<TFormSwitchButtonProps> = ({ text, isChecked, onChange }) => (
  <FormControlLabel
    control={
      <Switch
        checked={isChecked}
        onChange={onChange}
      />
    }
    label={text}
  />
);

FormSwitchButton.displayName = 'FormSwitchButton';
