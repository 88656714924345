import { Box, CloseButton, Flex, Text, BoxProps } from '@chakra-ui/react';
import { NavItem } from './nav-item';
import { navConfig } from '../nav-config';

interface ISidebarProps extends BoxProps {
    onClose: () => void;
}

export const SidebarContent = ({ onClose, ...rest }: ISidebarProps) => {
    return (
        <Box
            transition="3s ease"
            bg="white"
            borderRight="1px"
            borderRightColor="gray.200"
            w={{ base: 'full', md: 60 }}
            pos="fixed"
            h="full"
            {...rest}>
            <Flex h="20" alignItems="center" mx="8" justifyContent="space-between">
                <Text fontSize="2xl" fontFamily="monospace" fontWeight="bold">
                    Lexpec
                </Text>
                <CloseButton display={{ base: 'flex', md: 'none' }} onClick={onClose} />
            </Flex>
            {navConfig.pages.map(({ inSideDrawer, name, icon, href }) => (
                inSideDrawer && (
                    <NavItem key={name} icon={icon} href={href} closeSidebar={onClose}>
                        {name}
                    </NavItem>
                )))}
        </Box>
    );
};
