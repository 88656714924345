import type { CreatorNode } from './types/creator-types';
import type { DrawflowNodes } from '../types';

export function fromFlowNodesToCreatorNodes(flowNodes: DrawflowNodes): CreatorNode[] {
    const nodes = Object.values(flowNodes);
    
    const creatorQuestions = nodes.map(node => ({
        id: node.id,
        name: node.name,
        data: node.data,
        inputs: node.inputs,
        outputs: node.outputs,
        depthLevel: 0 // defautl 
    }));

    return creatorQuestions;
}