import React, { ChangeEvent, useContext } from 'react';

import type { TDocumentCard } from '../../../../../../types';
import { InspectionStore, InspectionStoreContext } from '../../../../store';
import { DocumentCardView } from './document-card.view';

export const DocumentCard: React.FC<TDocumentCard> = props => {
  const { name, role, content, isFile, id } = props;

  const store = useContext<InspectionStore>(InspectionStoreContext);

  function handleTextContentChange({ target: { value } }: ChangeEvent<HTMLInputElement>) {
    store.updateDocumentCardTextContent(id, value);
  }

  function handleSwitchButtonToggle() {
    store.toggleIsDocumentCardHasFile(id);
  }

  function handleDeleteClick() {
    store.removeDocumentCard(id);
  }

  function handleCardNameChange({ target: { value } }: ChangeEvent<HTMLInputElement>) {
    store.updateDocumentCard(id, { name: value });
  }

  return (
    <DocumentCardView
      id={id}
      name={name}
      role={role}
      isFile={isFile}
      content={content}
      onNameChange={handleCardNameChange}
      onSwitchButtonToggle={handleSwitchButtonToggle}
      onTextContentChange={handleTextContentChange}
      onDeleteClick={handleDeleteClick}
    />
  )
}

DocumentCard.displayName = 'DocumentCard';
