import React, { useState } from 'react';
import { Avatar, Button, TextField, Link, Box, Typography, Container } from '@mui/material';
import { LockOutlined } from '@mui/icons-material';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import { useAuthContext } from '../../auth';

function Copyright(props: any) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://lexpec.ru/">
        Lexpec.ru
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();

export const Login = () => {
  const [isCredentialsInvalid, setIsCredentialsInvalid] = useState(false);
  const authContext = useAuthContext();

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    try {
      await authContext.signIn({
        password: data.get('password')?.toString() || '',
        username: data.get('email')?.toString() || ''
      })
      setIsCredentialsInvalid(false);
    } catch (err) {
      setIsCredentialsInvalid(true);
    }
  };

  return (
    <Box display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      height="100%">
      <ThemeProvider
        theme={defaultTheme}>
        <Container component="main" maxWidth="xs">
          <Box
            sx={{
              marginTop: 8,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
              <LockOutlined />
            </Avatar>
            <Typography component="h1" variant="h5">
              Вход
            </Typography>
            <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
              <TextField
                error={isCredentialsInvalid}
                margin="normal"
                required
                fullWidth
                id="email"
                label="Логин"
                name="email"
                autoComplete="email"
                autoFocus
              />
              <TextField
                error={isCredentialsInvalid}
                helperText={isCredentialsInvalid && 'Неверный логин или пароль'}
                margin="normal"
                required
                fullWidth
                name="password"
                label="Пароль"
                type="password"
                id="password"
                autoComplete="current-password"
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Войти
              </Button>
            </Box>
          </Box>
          <Copyright sx={{ mt: 8, mb: 4 }} />
        </Container>
      </ThemeProvider>
    </Box>
  );
}
