import React from 'react';

import { css } from '@linaria/core';
import { DropzoneArea } from 'mui-file-dropzone';
import { OverridableComponent } from '@mui/types';
import { SvgIconTypeMap } from '@mui/material';

import { useFileInput } from './dnd-file-input.hooks';

type TDnDFileInputProps = {
  documentCardId: string;
  file: File | undefined;
}

export const DnDFileInput: React.FC<TDnDFileInputProps> = ({ documentCardId, file }) => {
  const { addFile, removeFile } = useFileInput(documentCardId);

  function handleChange([file]: File[]) {
    if (file) {
      addFile(file);
    } else {
      removeFile(documentCardId);
    }
  }

  const hasFile = Boolean(file);

  const icon = hasFile ?
    (() => null) as unknown as OverridableComponent<SvgIconTypeMap> & { muiName: string } :
    undefined
  ;

  return (
    <DropzoneArea
      Icon={icon}
      fileObjects={[file]}
      dropzoneClass={dropzoneClass}
      onChange={handleChange}
      acceptedFiles={acceptedFileTypes}
      filesLimit={1}
      maxFileSize={5000000}
      showFileNames
      dropzoneText={hasFile ? '' : 'Загрузить'}
      dropzoneProps={{
        disabled: hasFile,
      }}
      previewGridClasses={{
        container: loadedFilePreviewContainerClass,
        item: loadedFilePreviewItemClass,
      }}
      clearOnUnmount
      showPreviewsInDropzone
    />
  );
}

const acceptedFileTypes = ['application/vnd.openxmlformats-officedocument.wordprocessingml.document'];

DnDFileInput.displayName = 'DnDFileInput';

const dropzoneClass = css`
  min-height: 150px !important;
  min-width: 330px !important;
  padding: 0 4px;
`;

const loadedFilePreviewItemClass = css`
  & > svg {
    width: 50px;
    height: 50px;
    opacity: 0.3;
  }
  & > button {
    box-shadow: none;
    right: 8px;
  }
`;

const loadedFilePreviewContainerClass = css`
  display: flex;
  justify-content: center;
  align-items: center;
`;
