export enum CreatorQuestionTypeEnum {
    MULTIVAR_BUTTON = 'MULTIVAR_BUTTON',
    MULTIVAR_DROPDOWN = 'MULTIVAR_DROPDOWN',
    TEXT_INPUT = 'TEXT_INPUT',
    CONCLUSION = 'CONCLUSION',
}

export enum PresenterQuestionTypeEnum {
    radiogroup = 'radiogroup',
    dropdown = 'dropdown',
    text = 'text',
    conclusion = 'conclusion',
}

export const CONCLUSTION_SURVEY_TYPE = 'conclusion';
export const MULTIVAR_ANSWERS_FIELD = 'answers';
