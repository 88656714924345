import { useNavigate } from 'react-router-dom';
import { useStore } from '../../../../store';


export function useQuizCreatorNavigate() {
    const navigate = useNavigate();
    const store = useStore();

    return (id: number) => {
        navigate(`/quiz-creator/${id}`);
        store.setQuizId(id);
        store.initQuestionsState();
    }
}
