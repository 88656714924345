import { UseToastOptions } from "@chakra-ui/react";

const toastDefaultParams = {
    duration: 2000,
    isClosable: true,
}

export const quizSavedToast: UseToastOptions = {
    title: 'Опросник сохранен.',
    status: 'success',
    ...toastDefaultParams,
};

export const quizCreatedToast: UseToastOptions = {
    title: 'Опросник создан.',
    status: 'success',
    ...toastDefaultParams,
};