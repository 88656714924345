import { IconButton } from '@chakra-ui/react';
import { LuToyBrick } from 'react-icons/lu';
import { useSidenav } from './hooks';

export const SidebarButton = () => {
    const { onOpen } = useSidenav();

    return (
        <IconButton
            aria-label="questions drag"
            onClick={onOpen}
            icon={<LuToyBrick size={24}/>}
            colorScheme="green"
            pointerEvents="auto"
            isRound
        />
    );
}
