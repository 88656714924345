import { IconButton } from '@chakra-ui/react';
import { FaRegSave } from 'react-icons/fa';

export const QuizSaveButton = ({ onSave }: { onSave: () => void }) => (
    <IconButton
        aria-label="save"
        onClick={onSave}
        icon={<FaRegSave size={24} />}
        colorScheme="green"
        pointerEvents="auto"
    />
);
