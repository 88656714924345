import { extendTheme } from '@chakra-ui/react';

export const chakraTheme = extendTheme({
 components: {
    Drawer: {
      variants: {
        alwaysOpen: {
          parts: ['dialog, dialogContainer'],
          dialog: {
            pointerEvents: 'auto',
            maxW: '300px',
          },
          dialogContainer: {
            pointerEvents: 'none',
          },
        },
      },
    },
 },
});
