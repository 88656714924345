import type { DrawflowExport } from 'drawflow';
import { docGenerator } from '../../quiz-presenter/doc-generator';

export function uploadAsJson(file: File, saveData: (flowdata: DrawflowExport) => void) {
    const reader = new FileReader();

    reader.onload = function () {
        try {
            if (typeof reader.result == "string") {
                const quizData = JSON.parse(reader.result) as DrawflowExport;
                saveData(quizData);
            }
        } catch (error) {
            console.error('Не удалось загрузить файл:', error);
        }
    };

    reader.onerror = function (error) {
        console.error('Не удалось загрузить файл:', error);
    };

    reader.readAsText(file);
}

export function downloadAsJson(e: React.MouseEvent, quizData: DrawflowExport) {
    e.preventDefault();
    const fileData = JSON.stringify(quizData);
    const fileName = docGenerator.getFileName('дерево_решений', 'json');
    downloadFile(fileData, fileName);
}

function downloadFile(data: string, fileName: string) {
    const blob = new Blob([data], { type: 'text/json' });
    const a = document.createElement('a');
    a.download = fileName;
    a.href = window.URL.createObjectURL(blob);

    const clickEvent = new MouseEvent('click', {
        view: window,
        bubbles: true,
        cancelable: true,
    });

    a.dispatchEvent(clickEvent);

    a.remove();
};
