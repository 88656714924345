import { makeObservable, observable, action, configure } from 'mobx';
import { Api } from '../../../api';
import type { TDocumentCard, TDocumentCardDto, TDocumentCards, TAnalysisResult, TRules, TRuleResults, TEntityCategory } from '../../../types';
import { documentCardsInitValue } from './document-cards-init-value';
import { AuthStorage } from '../../../auth/auth-storage';

configure({ safeDescriptors: false });

export class InspectionStore {
  api = Api;
  rules: TRules | null = null;
  documentCards: TDocumentCards = documentCardsInitValue;
  ruleMatchResults: TRuleResults | null = null;
  mainDocEnts: TEntityCategory[] = [];
  supplDocEnts: TEntityCategory[] = [];
  isRulesLoading: boolean = false;
  isInspectionResultLoading: boolean = false;

  constructor() {
    makeObservable(this, {
      documentCards: observable,
      rules: observable,
      ruleMatchResults: observable,
      mainDocEnts: observable,
      supplDocEnts: observable,
      isRulesLoading: observable,
      isInspectionResultLoading: observable,
      setRules: action,
      setInspectionResults: action,
      toggleRule: action,
      addDocumentCard: action,
      removeDocumentCard: action,
      updateDocumentCard: action,
      startRulesLoading: action,
      endRulesLoading: action,
    });

    void this.init();
  }

   async init(): Promise<void> {
    const isAuthenticated = AuthStorage.hasAuthData();
    if (isAuthenticated) {
      await this.fetchRules();
    }
  }

   async fetchRules(): Promise<void> {
    this.startRulesLoading();
    const response = await this.api.fetchAvailableRules();
    if ('data' in response) {
      this.setRules(response.data);
    }
    this.endRulesLoading();
  }

   setRules(rules: TRules): void {
    this.rules = rules;
  }

   toggleRule(ruleId: string) {
    if (this.rules) {
      this.rules = this.rules.map((rule) => {
        if (rule.id === ruleId) {
          return {
            ...rule,
            isChecked: !rule.isChecked
          }
        }
        return rule;
      });
    }
  }

   addDocumentCard(documentCardParams: TDocumentCard) {
    this.documentCards = [...this.documentCards, documentCardParams];
  }

   addNewDocumentCard() {
    const usedIds = this.documentCards.map(({ id }) => Number(id));
    const freeId = this.getFreeId(usedIds).toString();
    this.addDocumentCard({
      id: freeId,
      name: '',
      role: 'supplementary',
      isFile: true,
      content: '',
    });
  }

   removeDocumentCard(cardId: string) {
    this.documentCards = this.documentCards.filter(({ id }) => id !== cardId);
  }

   addFileToDocumentCard(cardId: string, file: File) {
    this.updateDocumentCard(cardId, { content: file });
  }

   removeDocumentCardFile(cardId: string) {
    const { content } = this.findDocumentCardById(cardId);
    if (content) {
      this.updateDocumentCard(cardId, { content: undefined });
    }
  }

   findDocumentCardById(cardId: string) {
    return this.documentCards.find(({ id }) => id === cardId) as TDocumentCard;
  }

   toggleIsDocumentCardHasFile(cardId: string) {
    const card = this.findDocumentCardById(cardId);
    if (card) {
      this.updateDocumentCard(cardId, { isFile: !card.isFile, content: undefined });
    }
  }

   updateDocumentCardTextContent(cardId: string, content: string) {
    this.updateDocumentCard(cardId, { content });
  }

   updateDocumentCard(cardId: string, updatedCardFields: Partial<TDocumentCard>) {
    this.documentCards = this.documentCards.map((card) => {
      if (card.id === cardId) {
        return {
          ...card,
          ...updatedCardFields
        };
      }
      return card;
    });
  }

   startRulesLoading(): void {
    this.isRulesLoading = true;
  }

   endRulesLoading(): void {
    this.isRulesLoading = false;
  }

   startInspectionResultsLoading(): void {
    this.isInspectionResultLoading = true;
  }

   endInspectionResultsLoading(): void {
    this.isInspectionResultLoading = false;
  }

   setInspectionResults(results: TAnalysisResult) {
    this.mainDocEnts = results.mainDocEnts;
    this.supplDocEnts = results.suppDocEnts;
    this.ruleMatchResults = results.checkResult;
  }

   clearInspectionResults() {
    this.mainDocEnts = [];
    this.supplDocEnts = [];
    this.ruleMatchResults = null;
  }

   async submitDocumentsForInspection(): Promise<boolean> {
    const rulesToMatchIds = this.getRuleIdsToCheck();
    const isValid = this.validateDocumentCardContent();
    if (!isValid) {
      return false;
    }

    this.startInspectionResultsLoading();
    const response = await this.api.submitForInspection(this.documentCards as TDocumentCardDto[], rulesToMatchIds);
    if ('data' in response) {
      this.setInspectionResults(response.data);
    }
    this.endInspectionResultsLoading();
    return true;
  }

  private getRuleIdsToCheck() {
    if (this.rules) {
      return this.rules
        .filter(({ isChecked }) => isChecked)
        .map(({ id }) => id);
    }
    return [];
  }

  private validateDocumentCardContent(): boolean {
    if (this.documentCards.length === 0) {
      alert('Создайте хотя бы одну карточку документа для проверки.');
      return false;
    }

    for (let card of this.documentCards) {
      if (!card.content) {
        alert('Как минимум, в одной из карточек документов нет текста и не выбран какой-либо файл.');
        return false;
      }
    }

    return true;
  }

  private getFreeId(ids: number[]) {
    if (ids.length === 0) {
      return 0;
    }
  
    return Math.max(...ids) + 1;
  }  
}
