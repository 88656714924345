import { Box, Container, Flex, Heading, Icon, Stack, Text } from '@chakra-ui/react'
import { FcTreeStructure } from 'react-icons/fc'
import { AppCard, Footer } from './components'

export const Main = () => {
  return (
    <Box p={4}
      mt={12}
      bg="white"
      alignItems="center">
      <Stack spacing={4} as={Container} maxW="4xl" textAlign="center">
        <Heading fontSize={{ base: '2xl', sm: '4xl' }} fontWeight="bold">
          Портал LegalTech и TaxTech экспериментов
        </Heading>
        <Text color="gray.600" fontSize={{ base: 'sm', sm: 'lg' }}>
          Откройте для себя инновационные решения, которые автоматизируют рутинные задачи и освободят время для действительно важного.
        </Text>
      </Stack>
      <Container display="flex" flexDirection="column" maxW="5xl" mt={12} gap={16}>
        <Flex flexWrap="wrap" gridGap={6} justify="center">
          <AppCard 
            heading="Конструктор опросников"
            icon={<Icon as={FcTreeStructure} w={10} h={10} />}
            description="Удобное и быстрое создание опросников, справочных систем и генераторов документов"
            href="/quiz-creator/"
          />
        </Flex>
        <Footer />
      </Container>
    </Box>
  )
}
