import { BrowserRouter as Router } from 'react-router-dom';
import { ChakraProvider } from '@chakra-ui/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { AuthProvider } from './auth';
import { Routes } from './routes';
import { SidebarWithHeader } from './navigation';
import { chakraTheme } from './charka-theme';
import './app.css';

const App = () => {
  const queryClient = new QueryClient();
  return (
    <QueryClientProvider client={queryClient}>
      <ChakraProvider theme={chakraTheme}>
        <Router>
          <AuthProvider>
            <SidebarWithHeader>
              <Routes />
            </SidebarWithHeader>
          </AuthProvider>
        </Router>
      </ChakraProvider>
    </QueryClientProvider>
  );
};

export default App;
