import type { MRT_Row } from 'material-react-table';
import { Box, IconButton, Tooltip } from '@mui/material';
import { Edit, Delete, FileOpen } from '@mui/icons-material';
import type { TQuiz } from '../types';
import './index.css';

type TQuizActionsProps = {
    quiz: MRT_Row<TQuiz>;
    setEditiongQuiz: (row: MRT_Row<TQuiz>) => void;
    openQuizInCreator: (id: number) => void;
    deleteQuiz: (id: number) => void;
    openedQuizId: number | undefined;
}

export const QuizActions = ({ quiz, setEditiongQuiz, openQuizInCreator, deleteQuiz, openedQuizId }: TQuizActionsProps) => {
    const openDeleteConfirmModal = (quiz: MRT_Row<TQuiz>) => {
        if (window.confirm('Вы уверены, что хотите удалить этот опросник?')) {
            deleteQuiz(quiz.original.id);
        }
    };

    const handleQuizOpen = (quiz: MRT_Row<TQuiz>) => {
        openQuizInCreator(quiz.original.id);
    }

    return (
        <Box sx={{ display: 'flex', gap: '1rem' }}>
            {quiz.original.id !== openedQuizId && <Tooltip title="Открыть">
                <IconButton
                    color="primary"
                    onClick={() => handleQuizOpen(quiz)}>
                    <FileOpen />
                </IconButton>
            </Tooltip>}
            <Tooltip title="Редактировать">
                <IconButton onClick={() => setEditiongQuiz(quiz)}>
                    <Edit />
                </IconButton>
            </Tooltip>
            <Tooltip title="Удалить">
                <IconButton color="error" onClick={() => openDeleteConfirmModal(quiz)}>
                    <Delete />
                </IconButton>
            </Tooltip>
        </Box>
    );
}
