import React from 'react';
import { observer } from 'mobx-react';
import { Button } from '@chakra-ui/react';
import { PlayArrow, HourglassBottom, Clear, Print } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { Transition } from 'react-transition-group';
import { styled } from '@linaria/react';

import { BorderedBox } from '../../../common/components';
import { InspectionResults } from './components';
import type { TEntityCategory, TRuleResults, TRules } from '../../../types';
import ReactToPrint from 'react-to-print';

type TControlsBlockView = {
  onSubmitClick: () => void;
  onClearClick: () => void;
  hideResults: () => void;
  isLoading: boolean;
  isResultsVisible: boolean;
  results: TRuleResults | null;
  mainDocEnts: TEntityCategory[];
  supplDocEnts: TEntityCategory[];
  rules: TRules | null;
  resultsTableRef: React.MutableRefObject<HTMLDivElement | null>;
  docsCount: number
}

export const ControlsBlockView: React.FC<TControlsBlockView> = observer((
  { onSubmitClick, onClearClick, hideResults, isLoading, isResultsVisible, 
    results, rules, resultsTableRef, mainDocEnts, supplDocEnts, docsCount }
) => {

  function handleTransitionEndAfterResultsFold() {
    if (!isResultsVisible) {
      onClearClick();
    }
  }

  return (
    <BorderedBox>
      <ButtonsPanel>
        <InspectionButtons>
          <Button
            onClick={onSubmitClick}
            variant="contained"
            size="medium"
            disabled={isLoading}
            rightIcon={isLoading ? <HourglassBottom /> : <PlayArrow />}
          >
            <>Проверить</>
          </Button>
          <Button
            onClick={hideResults}
            variant="outlined"
            size="medium"
            disabled={isLoading}
            rightIcon={<Clear />}
          >
            <>Очистить</>
          </Button>
        </InspectionButtons>
        {results && resultsTableRef && (
          <ReactToPrint
            content={() => resultsTableRef.current}
            trigger={() => (
              <IconButton color="primary">
                <Print />
              </IconButton>
            )}
            pageStyle={getPrintingPageStyle()}
          />
        )}
      </ButtonsPanel>
      <Transition in={isResultsVisible} timeout={0}>
        {state => (
          <div onTransitionEnd={handleTransitionEndAfterResultsFold} style={getTransitionStyle(state)}>
            <InspectionResults 
              tableRef={resultsTableRef} 
              mainDocEnts={mainDocEnts}
              supplDocEnts={supplDocEnts}
              results={results} 
              rules={rules}
              docsCount={docsCount} />
          </div>
        )}
      </Transition>
    </BorderedBox>
  );
});

ControlsBlockView.displayName = ' ControlsBlockView';

const getPrintingPageStyle = () => {
  return `@page { margin: 12 12 12 12 }`;
};

const ButtonsPanel = styled.div`
  display: flex;
  justify-content: space-between;
`;

const InspectionButtons = styled.div`
  display: flex;
  gap: var(--space-200);
`;

const getTransitionStyle = (state: string) => ({
  transition: 'all 0.4s ease',
  overflow: 'hidden',
  maxHeight: state === 'exited' ? 0 : 5000,
});