import React from 'react';
import {
  Box,
  Table, TableBody, TableCell,
  TableHead, TableRow, Typography
} from '@mui/material';
import { Error, CheckCircle } from '@mui/icons-material';
import { styled } from '@linaria/react';

import type { TEntityCategory, TRuleResults, TRules } from '../../../../types';
import { NamedEntities } from './named-entities';

type TInspectionResultsProps = {
  tableRef: React.MutableRefObject<HTMLDivElement | null>;
  results: TRuleResults | null;
  mainDocEnts: TEntityCategory[];
  supplDocEnts: TEntityCategory[];
  rules: TRules | null;
  docsCount: number;
}

export const InspectionResults: React.FC<TInspectionResultsProps> = (
  { results, rules, tableRef, mainDocEnts, supplDocEnts, docsCount }
) => { 
  const rulesResult = rules && results && results.length > 0 ? (
    <div ref={tableRef}>
      <ResultsHeading>Результаты проверки документов</ResultsHeading>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell sx={{ width: 20 }} />
            <TableCell align="left">Правило</TableCell>
            <TableCell align="left">Контекст</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {results.map(({ id, failDescription, isSuccess }) => (
            <TableRow
              key={id}
            >
              <TableCell align="left" sx={{ width: 20 }}>
                {isSuccess ? <CheckCircle color="success" /> : <Error color="error" />}
              </TableCell>
              <TableCell align="left" sx={getTextStyle(isSuccess)}>
                {getRuleName(rules, id)}
              </TableCell>
              <TableCell align="left">
                {failDescription ? (
                  <Typography sx={{ fontSize: 14 }}>
                    <Box>{failDescription}</Box>
                  </Typography>
                ) : '—'}
                {/*{failDescription ? failDescription.textFragments.map((text) => (*/}
                {/*  <Typography sx={{ fontSize: 14 }}>*/}
                {/*    <Box>{'...' + text + '...'}</Box>*/}
                {/*  </Typography>*/}
                {/*)) : '—'}*/}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  ) : null
  return <ResultsStyle>
    <NamedEntities mainDocEnts={mainDocEnts} supplDocEnts={supplDocEnts} docsCount={docsCount}/>
    {rulesResult}
  </ResultsStyle>
}

InspectionResults.displayName = 'InspectionResults';

function getRuleName(rules: TRules, targetRuleId: string) {
  const rule = rules.find(rule => rule.id === targetRuleId);
  console.log(rules)
  console.log(targetRuleId)
  if (rule) {
    return rule.displayName;
  }
  return 'Название правила не найдено';
}

function getTextStyle(isSuccess: boolean) {
  if (isSuccess) {
    return {
      color: '#3c763d'
    };
  }
  return {
    color: 'red'
  }
}

const ResultsStyle = styled.div`
  margin: var(--space-200) 0 0 0;
`;

const ResultsHeading = styled.div`
  @media screen {
    display: none;
  }
  @media print {
    display: block;
  }
`;
