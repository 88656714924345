import { Grid, GridItem } from '@chakra-ui/react';
import { observer } from 'mobx-react';
import { ControlsTopPanel, ControlsBottomPanel, SidenavProvider, SideInstruments } from './components';
import { CreatorQuestionTypeEnum } from '../../constants'
import type { CardToPickValues, CreatorQuestionType } from '../../types';
import { useCreator } from './hooks';

export const Creator = observer(() => {
  const creator = useCreator();

  function touchStart(e) {
    const questionType = e.target.closest('.pickable-card').getAttribute('data-node') as CreatorQuestionType;
    creator.setMobileQuestionType(questionType);
  }

  function touchEnd() {
    if (creator.mobileLastMove) {
      const xPos = creator.mobileLastMove.touches[0].clientX;
      const yPos = creator.mobileLastMove.touches[0].clientY;

      const movingElement = document.elementFromPoint(xPos, yPos);
      if (movingElement && creator.mobileQuestionType) {
        creator.addNode(creator.mobileQuestionType, xPos, yPos);
      }
    }
    creator.setMobileQuestionType(null);
  }

  function drag(e) {
    const data = e.target.getAttribute('data-node');
    e.dataTransfer.setData('node', data);
  }

  function drop(e) {
    const data = e.dataTransfer.getData('node');
    creator.addNode(data, e.clientX, e.clientY);
  }

  return (
    <SidenavProvider>
      <Grid templateAreas={`'main sidebar'`} templateColumns="1fr auto" bg="gray.100" p={4}>
        <GridItem area="main">
          <div id="drawflow" style={flowAreaStyle} onDrop={drop} onDragOver={allowDrop}>
            {creator.isLoaded && <>
              <ControlsTopPanel
                upload={creator.handleUpload}
                download={creator.handleDownload}
                clear={creator.clear}/>
              <ControlsBottomPanel
                toggleLock={creator.toggleLock}
                isLocked={creator.isLocked}
                zoomIn={creator.zoomIn}
                zoomOut={creator.zoomOut}
                zoomReset={creator.zoomReset} />
            </>}
          </div>
        </GridItem>
        <GridItem area="sidebar">
          <SideInstruments
            imageUrl={creator.theme?.backgroundImage}
            updateImageURL={creator.setBackgroundImage}
            cardToPickValues={cardsToPick}
            drag={drag}
            touchStart={touchStart}
            touchEnd={touchEnd}
            touchMove={creator.positionMobile} />
          <div id="target"></div>
        </GridItem>
      </Grid>
    </SidenavProvider >
  );
});

const cardsToPick: CardToPickValues = [
  { text: 'Выбор из нескольких вариантов', questionType: CreatorQuestionTypeEnum.MULTIVAR_BUTTON },
  { text: 'Поле для заполнения', questionType: CreatorQuestionTypeEnum.TEXT_INPUT },
  { text: 'Финальный вывод', questionType: CreatorQuestionTypeEnum.CONCLUSION },
];

const flowAreaStyle = {
  width: '100%',
  height: 'calc(100vh - 112px)',
  top: '0',
  display: 'flex',
  flexDirection: 'column' as 'column',
  '& > .drawflow': {
    order: 2
  }
}

function allowDrop(e) {
  e.preventDefault();
}
