import { ChangeEvent } from 'react';
import { css } from '@linaria/core';

type QuestionHeaderProps = {
    value: string;
    placeholder?: string;
    onChange: (e: ChangeEvent<HTMLInputElement>) => void;
};

export const QuestionHeader: React.FC<QuestionHeaderProps> = ({ value, placeholder = " Заголовок...", onChange }) => (
    <div className={wrapperClassName}>
        <input type="text"
            className={inputClassName}
            onChange={onChange}
            value={value}
            placeholder={placeholder}
            df-db-dbname="true" />
    </div>
);

const wrapperClassName = css`
    height: 50px;
    line-height: 50px;
    background: var(--background-box-title);
    border-bottom: 1px solid #e9e9e9;
    border-radius: 4px 4px 0px 0px;
    padding: 0 8px;
`;

const inputClassName = css`
    width: 100%!important;
`;

