import { FiHome } from 'react-icons/fi';
import { PiTreeStructure } from 'react-icons/pi';
import type { IconType } from 'react-icons';

interface IPageNavConfig {
    name: string;
    href: string;
    mask: RegExp;
    icon: IconType;
    inSideDrawer: boolean;
    sideDrawerVisible: boolean;
    headerVisible: boolean;
}

const pages: Array<IPageNavConfig> = [
    {
        name: 'Главная',
        href: '/',
        mask: /^\/$/,
        icon: FiHome,
        inSideDrawer: true,
        headerVisible: true,
        sideDrawerVisible: true
    },
/*     {
        name: 'Проверка документов',
        href: '/inspection',
        icon: MdRule,
        inSideDrawer: true,
        headerVisible: true,
        sideDrawerVisible: true
    }, */
    {
        name: 'Конструктор опросников',
        href: '/quiz-creator',
        mask: /^\/quiz-creator(.*)/,
        icon: PiTreeStructure,
        inSideDrawer: true,
        headerVisible: true,
        sideDrawerVisible: true
    },
    {
        name: 'Опросник (тест)',
        href: '/quiz-test',
        mask: /^\/quiz-test\/(.*)/,
        icon: PiTreeStructure,
        inSideDrawer: false,
        headerVisible: false,
        sideDrawerVisible: false
    },
    {
        name: 'Опросник (опубликованный)',
        href: '/quiz',
        mask: /^\/quiz\/(.*)/,
        icon: PiTreeStructure,
        inSideDrawer: false,
        headerVisible: false,
        sideDrawerVisible: false
    },
    {
        name: 'LogIn',
        href: '/login',
        mask: /^\/login$/,
        icon: PiTreeStructure,
        inSideDrawer: false,
        headerVisible: false,
        sideDrawerVisible: false
    },
];

export const navConfig = {
    pages,
}

export const defaultConfig: IPageNavConfig = {
    name: '',
    href: '/',
    mask: /\//,
    icon: FiHome,
    inSideDrawer: false,
    headerVisible: false,
    sideDrawerVisible: true
};
