import { Button } from '@chakra-ui/react';
import { css } from '@linaria/core';
import type { Model, Question } from 'survey-core';
import { docGenerator } from '../doc-generator';
import { CheckIcon, DownloadIcon } from '@chakra-ui/icons';

type FinishCardProps = {
    onFinish: () => void;
    title: string;
    description: string;
    surveyModel: Model;
    fileHash: string | null;
}

export const FinalQuestion: React.FC<FinishCardProps> = ({ onFinish, fileHash, surveyModel, title, description }) => {
    async function handleDownloadClick() {
        if (fileHash) {
            const allQuestions = surveyModel.getAllQuestions();
            const tagValues = prepareTagValues(surveyModel.data, allQuestions);
            const doc = await docGenerator.generate(fileHash, tagValues);
            const fileName = docGenerator.getFileName('сгенерированный_документ', 'docx')
            docGenerator.download(doc, fileName);
        }
    }

    return (
        <div className={className}>
            {fileHash && (
                <Button
                    onClick={handleDownloadClick}
                    colorScheme="green"
                    leftIcon={<DownloadIcon />}
                    variant="solid">
                    Скачать
                </Button>
            )}
            <Button
                onClick={onFinish}
                colorScheme="green"
                leftIcon={<CheckIcon />}
                variant="solid">
                Завершить
            </Button>
        </div>
    )
};

function prepareTagValues(asweredQuestions: Model["data"][], allQuestions: Question[]) {
    const tagValues = {};
    for (const questionId in asweredQuestions) {
        const answer = asweredQuestions[questionId];
        const questionMeta = allQuestions.find(qstn => qstn.name === questionId);
        if (questionMeta) {
            const tag = questionMeta.tag;
            tagValues[tag] = answer;
        }
    }

    return tagValues;
}

const className = css`
    display: flex;
    justify-content: center;
    gap: 8px;
`;