import { observer } from 'mobx-react';
import type { BaseQuestionDefaults } from '../../../../../types';
import { QuestionAccordion, QuestionDescription, QuestionHeader, QuestionTag, questionClassName } from '../common';
import { useTextInput } from './hooks';

type TextInputProps = {
    id: number;
    connRender: () => void;
    defaults?: BaseQuestionDefaults;
};

export const TextInputQuestion: React.FC<TextInputProps> = observer(({ id, connRender, defaults }) => {
    const { title, description, tag, isTagVisible, updateTitle, updateDescription, updateTag } = useTextInput(id, connRender, defaults);

    return (
        <>
            <QuestionHeader value={title} onChange={updateTitle} />
            <div className={questionClassName}>
                <QuestionAccordion buttonText="Описание вопроса">
                    <QuestionDescription value={description} onChange={updateDescription} />
                </QuestionAccordion>
                <QuestionTag isVisible={isTagVisible} value={tag} onChange={updateTag}/>
            </div>
        </>
    );
});
