import { useMemo } from 'react';
import type { DropdownOption, MRT_ColumnDef } from 'material-react-table';
import type { TQuiz } from '../types';
import type { TValidationErrors } from './use-validation-errors';
import { LinkCell } from '../components';

export function useTableColumns(validationErrors: TValidationErrors, setValidationErrors: React.Dispatch<React.SetStateAction<TValidationErrors>>) {
    return useMemo<MRT_ColumnDef<TQuiz>[]>(
        () => [
            {
                accessorKey: 'id',
                header: 'id',
                enableHiding: true,
            },
            {
                accessorKey: 'name',
                header: 'Название',
                muiEditTextFieldProps: {
                    required: true,
                    error: !!validationErrors?.name,
                    helperText: validationErrors?.name,
                    onFocus: () => setValidationErrors({ ...validationErrors, name: undefined }),
                },
            },
            {
                accessorKey: 'title',
                header: 'Заголовок',
                muiEditTextFieldProps: {
                    required: true,
                    error: !!validationErrors?.title,
                    helperText: validationErrors?.title,
                    onFocus: () => setValidationErrors({ ...validationErrors, title: undefined }),
                },
            },
            {
                accessorKey: 'access',
                header: 'Доступ',
                editVariant: 'select',
                editSelectOptions: accessVariantsVisible,
                muiEditTextFieldProps: {
                    select: true,
                    error: !!validationErrors?.access,
                    helperText: validationErrors?.access,
                    defaultValue: accessVariantsVisible[ACCESS_CREATOR]
                },
            },
            {
                accessorKey: 'linkHash',
                header: 'Ссылка',
                enableEditing: false,
                Cell: ({ cell }) => <LinkCell linkHash={cell.getValue()}/>,
                size: 300
            }
        ],
        [validationErrors]
    );
}

export const ACCESS_EVERYONE = 'любой по ссылке';
export const ACCESS_CREATOR = 'только создатель';

export const accessVariantsVisible: DropdownOption[] = [
    ACCESS_EVERYONE,
    ACCESS_CREATOR,
];

export const accessVariantsToDisplay = {
    [ACCESS_EVERYONE]: 'everyone',
    [ACCESS_CREATOR]: 'creator',
}

export const accessVariantsToValue = {
    'everyone': [ACCESS_EVERYONE],
    'creator': [ACCESS_CREATOR],
}
