import { Box, Text } from '@chakra-ui/react'

export const Footer = () => (
    <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center">
        <Text textAlign="center" fontSize='s' color="gray">Готовы к сотрудничеству или хотите попробовать наши продукты?</Text>
        <Text textAlign="center" fontSize='s' color="gray">Пишите на lexpec@yandex.ru</Text>
    </Box>
);
