import { styled } from '@linaria/react';
import { css } from '@linaria/core';
import { IconButton } from '@chakra-ui/react'
import { AddIcon, DeleteIcon } from '@chakra-ui/icons';
import type { Answers } from './../../../../../types';

type AnswerControlsProps = {
    answers: Answers;
    index: number;
    addAnswer: () => void;
    removeAnswer: () => void;
};

export const AnswerControls: React.FC<AnswerControlsProps> = ({ answers, addAnswer, removeAnswer, index }) => {
    if (answers.length - 1 > index) {
        return null;
    }

    const addButton = (
        <IconButton
            className={buttonStyle}
            aria-label='add answer'
            icon={<AddIcon />}
            onClick={addAnswer} />
    );

    if (answers.length < 2) {
        return addButton;
    }

    return (
        <LastAnswerControls>
            {addButton}
            <IconButton
                className={buttonStyle}
                aria-label='remove answer'
                icon={<DeleteIcon />}
                onClick={removeAnswer} />
        </LastAnswerControls>
    );
}

const LastAnswerControls = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: start;
    gap: 4px;
`;

const buttonStyle = css`
    width: 20px;
`;
