import { useLocation } from 'react-router-dom';
import { defaultConfig, navConfig } from './nav-config';

export function useNavConfig() {
    const location = useLocation();

    const pageNavConfig = navConfig.pages.find(page => {
        return page.mask.test(location.pathname)
    });
    
    if (pageNavConfig) {
        return pageNavConfig;
    }

    return defaultConfig;
}