export function getQuizHashFromPath() {
    const path = window.location.pathname;
    const fragments = path.split('/');
    const linkHash = fragments[fragments.length - 1];

    if (linkHash) {
      return linkHash;
    }

    return undefined;
}
