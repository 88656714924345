import PizZip from 'pizzip';
import Docxtemplater from 'docxtemplater';
import { Api } from '../../../api';

type TagValues = {
    [key: string]: string | number
};

class DocGenerator {
    async generate(fileHash: string, tagValues: TagValues) {
        const buffer = await Api.downloadFile(fileHash);
        return this.createDocFromTemplate(buffer, tagValues);
    }

    private createDocFromTemplate(template: Buffer, tagValues: TagValues): Blob {
        const zip = new PizZip(template);
        const doc = new Docxtemplater();
        try {
            return doc
                .loadZip(zip)
                .setOptions(templaterOptions)
                .setData(tagValues)
                .render()
                .getZip()
                .generate(formatterOptions);
        } catch (err) {
            throw err;
        }
    }

    download(binary: Blob, fileName = 'документ.docx') {
        const docUrl = window.URL.createObjectURL(binary);

        const anchor = document.createElement('a');
        anchor.style.display = 'none';
        anchor.download = fileName;
        anchor.href = docUrl;

        document.body.appendChild(anchor);
        anchor.click();

        window.URL.revokeObjectURL(docUrl);
    }

    getFileName(namePrefix: string, ext: string) {
        const date = new Date();
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');
        const seconds = date.getSeconds().toString().padStart(2, '0');
    
        const formattedDateTime = `${year}-${month}-${day}_${hours}-${minutes}-${seconds}`;
        return `${namePrefix}_${formattedDateTime}.${ext}`;
    }
}

const templaterOptions = { nullGetter: () => '' };
const formatterOptions = { type: 'blob' };

export const docGenerator = new DocGenerator();
