import { ReactNode } from 'react';
import { Drawer, Grid, GridItem, DrawerContent, useDisclosure, useMediaQuery } from '@chakra-ui/react';
import { Header, SidebarContent } from './components';
import { useNavConfig } from './nav-config';

export const SidebarWithHeader = ({ children }: { children: ReactNode }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { headerVisible, sideDrawerVisible } = useNavConfig();
  const [isLargerThan768] = useMediaQuery('(min-width: 768px)');

  if (!headerVisible && !sideDrawerVisible) {
    return <>{children}</>;
  }

  return (
    <Grid width="100%"
      height="100%"
      templateAreas={`
        ${isLargerThan768 ? "'sidebar header'" : "'header'"}
        ${isLargerThan768 ? "'sidebar content'" : "'content'"}
      `}
      templateRows={headerVisible ? `80px 1fr` : `0 1fr`}
      templateColumns={isLargerThan768 && sideDrawerVisible ? `240px 1fr` : `1fr`}
    >
      <GridItem area="header">
        {headerVisible && <Header onOpen={onOpen} />}
      </GridItem>
      <GridItem area="sidebar">
        {headerVisible && <SidebarContent onClose={onClose} display={{ base: 'none', md: 'block' }} />}
        {sideDrawerVisible && <Drawer
          autoFocus={false}
          isOpen={isOpen}
          placement="left"
          onClose={onClose}
          returnFocusOnClose={false}
          onOverlayClick={onClose}>
          <DrawerContent>
            <SidebarContent onClose={onClose} />
          </DrawerContent>
        </Drawer>}
      </GridItem>
      <GridItem
        area="content"
        height="100%"
        overflowY="auto">
        {children}
      </GridItem>
    </Grid>
  );
}
