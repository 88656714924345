import { useEffect, useState } from 'react'
import type { ConclusionDefaults } from '../../../../../../types';
import { useStore } from '../../../../store';

export const useConslusion = (questionId: number, connRerender: () => void, defaults?: ConclusionDefaults) => {
    let defaultTitle = '';
    let defaultDescription = '';

    if (defaults) {
        defaultTitle = defaults.title;
        defaultDescription = defaults.description;
    }

    const [title, setTitle] = useState(defaultTitle);
    const [description, setDescription] = useState(defaultDescription);
    const store = useStore();
    useEffect(connRerender, []);

    function updateTitle({ target: { value } }: React.ChangeEvent<HTMLInputElement>) {
        setTitle(value);
        store.updateNodeData(questionId, { title: value });
    }

    function updateDescription({ target: { value } }: React.ChangeEvent<HTMLTextAreaElement>) {
        setDescription(value);
        store.updateNodeData(questionId, { description: value });
    }

    return {
        title,
        description,
        updateTitle,
        updateDescription,
    }
}
