import { useNavigate } from 'react-router-dom';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useToast } from '@chakra-ui/react';
import { Api } from '../../../../../../api';
import type { TQuiz } from '../types';
import { accessVariantsToDisplay } from './use-table-columns';
import { useStore } from '../../../../store';
import { quizCreatedToast } from '../../../toasts';

export function useCreateQuiz() {
  const toast = useToast();
  const navigate = useNavigate();
  const store = useStore();
  const queryClient = useQueryClient();
  
  const result = useMutation({
    mutationFn: ({ access, name, title }) => {
      const { creatorContent, presenterContent, presenterTheme } = store.prepareForSave();

      return Api.createQuiz({
        name,
        title,
        access: accessVariantsToDisplay[access],
        creatorContent,
        presenterContent,
        presenterTheme,
      })
    },
    onMutate: (newQuizInfo: TQuiz) => {
      queryClient.setQueryData(
        ['quizzes'],
        (prevQuizzes: any) =>
          [
            ...prevQuizzes,
            { ...newQuizInfo },
          ] as TQuiz[],
      );
    },
    onSuccess: (quiz) => {
      toast(quizCreatedToast);
      navigate(`/quiz-creator/${quiz.id}`);
      store.setQuizId(quiz.id);
    },  
    onSettled: () => queryClient.invalidateQueries({ queryKey: ['quizzes'] }), //refetch quizzes after mutation
  });

  return {
    createQuiz: result.mutateAsync,
    isCreatingQuiz: result.isPending,
  }
}
