import { useNavigate } from 'react-router-dom';
import decodeJwt from 'jwt-decode';

import { AuthApi } from '../api/auth-api'
import type { Credentials, AccessToken } from '../types/auth';
import { useLocalStorage } from './use-local-storage';
import { ACCESS_TOKEN_KEY, USERNAME_KEY } from './constants';

export const useAuth = () => {
    const navigate = useNavigate();
    const [userName, setUserName] = useLocalStorage(USERNAME_KEY);
    const [accessToken, setAccessToken] = useLocalStorage(ACCESS_TOKEN_KEY);

    const signIn = async (creds: Credentials) => {
        try {
            const { data } = await AuthApi.login(creds);
            const decodedToken = decodeJwt<AccessToken>(data.accessToken);
            if (typeof decodedToken.username !== 'string') {
                throw new Error('No username provided.');
            }

            setAccessToken(data.accessToken);
            setUserName(decodedToken.username);
            navigate('/');
        } catch (err) {
            setUserName(null);
            setAccessToken(null);
            throw new Error('Failed to signIn');
        }
    };

    const signUp = async (data: unknown) => {
    };

    const signOut = () => {
        setUserName(null);
        setAccessToken(null);
        navigate('/')
    };

    return { userName, accessToken, signIn, signUp, signOut };
};