export const getClassSecriotionByLabel = (label: string) => {
    if (label in entityClassToDescriptionMapping) {
        return entityClassToDescriptionMapping[label]
    }
    return "Неопределенная категория"
}

const entityClassToDescriptionMapping: Record<string, string> = {
    CASEREF: 'Ссылки на материалы дела',
    PROTOCOL: 'Протоколы',
    EXPERT_OPINION: 'Заключения экспертов',
    MATERIAL_EVIDENCE: 'Вещественные доказательства',
    PARTICIPANT: 'Участники дела',
    TESTIMONY: 'Показания участников дела',
    OTHER_DOCUMENT: 'Иные документы',
    MATERIAL_EVIDENCE_ADD: '',
    CONTRACT: 'Договоры',
}
