import { ChakraProvider } from '@chakra-ui/react';
import { chakraTheme } from '../../charka-theme';
import { SurveyPresenter } from './survey-presenter';
import { PresenterStore, PresenterStoreProvider } from './store';

export const QuizPresenter = ({ isDevMode = false }: { isDevMode?: boolean }) => {
    const store = new PresenterStore(isDevMode);

    return (
        <PresenterStoreProvider store={store}>
            <ChakraProvider theme={chakraTheme}>
                <SurveyPresenter />
            </ChakraProvider>
        </PresenterStoreProvider>
    );
};
