import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Api } from '../../../../../../api';
import type { TQuiz } from '../types';

export function useDeleteQuiz() {
  const queryClient = useQueryClient();

  const result = useMutation({
    mutationFn: (quizId) => Api.deleteQuiz(quizId),
    //client side optimistic update
    onMutate: (quizId: number) => {
      queryClient.setQueryData(['quizzes'], (prevQuizzes: any) =>
        prevQuizzes?.filter((quiz: TQuiz) => quiz.id !== quizId),
      );
    },
    onSettled: () => queryClient.invalidateQueries({ queryKey: ['quizzes'] }), //refetch quizzes after mutation
  });

  return {
    deleteQuiz: result.mutateAsync,
    isDeletingQuiz: result.isPending,
  }
}
