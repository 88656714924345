import { ChangeEvent, useState } from 'react';
import { CiEdit } from 'react-icons/ci';
import {
    useDisclosure, Button, Modal, ModalOverlay, ModalContent, ModalHeader,
    ModalCloseButton, ModalBody, ModalFooter, FormControl, FormLabel, Input, Text,
} from '@chakra-ui/react';

type LinkSaverProps = {
    updateUrl: (url: string) => void;
    name: string;
    urlPlaceholder?: string;
    defaultUrl?: string;
    description?: string;
}

export const LinkSaver: React.FC<LinkSaverProps> = ({ defaultUrl, updateUrl: updateURL, urlPlaceholder,  name, description }) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [url, setUrl] = useState(defaultUrl);

    function handleUrlChange(evt: ChangeEvent<HTMLInputElement>) {
        setUrl(evt.target.value);
    }

    function handleSaveClick() {
        if (url) {
            updateURL(url);
        }
        onClose();
    }

    return (
        <>
            <FormControl display="flex">
                <Button
                    leftIcon={<CiEdit />}
                    onClick={onOpen}
                    variant="link"
                    aria-label="choose-image">
                    {name}
                </Button>
            </FormControl>
            <Modal
                isOpen={isOpen}
                onClose={onClose}
                isCentered
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader borderBottom={0}>{name}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody pb={6}>
                        {description && <Text>{description}</Text>}
                        <FormControl>
                            <FormLabel>Ссылка</FormLabel>
                            <Input
                                onChange={handleUrlChange}
                                value={url}
                                placeholder={urlPlaceholder ? urlPlaceholder : 'http://example.ru/image?id=123'} />
                        </FormControl>
                    </ModalBody>
                    <ModalFooter>
                        <Button onClick={handleSaveClick} colorScheme='blue' mr={3}>
                            Сохранить
                        </Button>
                        <Button onClick={onClose}>Отмена</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}