import { ChangeEvent, useContext, useEffect, useState } from 'react';
import type { TRules } from '../../../types';
import { InspectionStore, InspectionStoreContext } from '../store';

export function useRules() {
  const store = useContext<InspectionStore>(InspectionStoreContext);
  const [filteredRules, setFilteredRules] = useState<TRules>([]);

  useEffect(() => {
    if (store.rules) {
      const filteredRules = filterRules(store.rules, '');
      setFilteredRules(filteredRules);
    }
  }, [store.rules])


  function onRuleCheckClick({ target: { value } }: ChangeEvent<HTMLInputElement>) {
    store.toggleRule(value);
  }

  return {
    rules: filteredRules,
    isLoading: store.isRulesLoading,
    onRuleCheckClick,
  }
}

function filterRules(rules: TRules, filter: string) {
  const filterValue = filter.trim();
  const filterRegex = new RegExp(filterValue, 'i');
  return rules.filter(({ displayName }) =>
    filterRegex.test(displayName)
  );
}
