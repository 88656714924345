import { ChangeEvent } from 'react';
import { FormControl, FormLabel, Input } from '@chakra-ui/react';

type QuestionTagProps = {
    value: string;
    isVisible?: boolean;
    onChange: (e: ChangeEvent<HTMLInputElement>) => void;
};

export const QuestionTag: React.FC<QuestionTagProps> = ({ value, isVisible, onChange }) => (
    isVisible ? (
        <FormControl display="flex" flexDirection="row" gap={8}>
            <FormLabel lineHeight="32px">Тэг</FormLabel>
            <Input type="text"
                onChange={onChange}
                value={value} 
                maxWidth={128}
                df-db-dbname="true"/>
        </FormControl>
    ) : null
);