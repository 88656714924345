import { useNavigate, useLocation } from 'react-router-dom';
import {
    IconButton, Avatar, Box, Flex, HStack, VStack, Text, FlexProps, Menu, MenuButton, Button, MenuItem, MenuList,
} from '@chakra-ui/react';
import { FiMenu, FiChevronDown } from 'react-icons/fi';
import { MdOutlineLogout } from 'react-icons/md';
import { useAuthContext } from '../../auth';

interface IHeaderProps extends FlexProps {
    onOpen: () => void;
}

export const Header = ({ onOpen, ...rest }: IHeaderProps) => {
    const location = useLocation();
    const navigate = useNavigate();
    const { userName, signOut } = useAuthContext();

    const handleSignInClick = () => navigate('/login');

    const isNotLoginPage = location.pathname !== '/login';

    return (
        <Flex
            ml={{ base: 0 }}
            px={{ base: 4, md: 4 }}
            height="20"
            alignItems="center"
            borderBottomWidth="1px"
            bg="white"
            borderBottomColor="gray.200"
            justifyContent={{ base: 'space-between', md: 'flex-end' }}
            {...rest}>
            <IconButton
                display={{ base: 'flex', md: 'none' }}
                onClick={onOpen}
                variant="outline"
                aria-label="open menu"
                icon={<FiMenu />}
            />
            <Text
                display={{ base: 'flex', md: 'none' }}
                fontSize="2xl"
                fontFamily="monospace"
                fontWeight="bold">
                Lexpec
            </Text>
            <HStack spacing={{ base: '0', md: '6' }}>
                <Flex alignItems="center">
                    {!userName && isNotLoginPage && (
                        <Button onClick={handleSignInClick} color="inherit">Войти</Button>
                    )}
                    {userName && (
                        <Menu>
                            <MenuButton
                                py={2}
                                rounded="full"
                                transition="all 0.3s"
                                _focus={{ boxShadow: 'none' }}>
                                <HStack>
                                    <Avatar size="sm" />
                                    <VStack
                                        display={{ base: 'none', md: 'flex' }}
                                        alignItems="flex-start"
                                        spacing="1px"
                                        ml="2">
                                        <Text fontSize="sm">{userName}</Text>
                                        <Text fontSize="xs" color="gray.600">
                                            Пользователь
                                        </Text>
                                    </VStack>
                                    <Box display={{ base: 'none', md: 'flex' }}>
                                        <FiChevronDown />
                                    </Box>
                                </HStack>
                            </MenuButton>
                            <MenuList zIndex={3}>
                                <MenuItem onClick={signOut} icon={<MdOutlineLogout size={20} />}>
                                    Выйти
                                </MenuItem>
                            </MenuList>
                        </Menu>
                    )}
                </Flex>
            </HStack>
        </Flex>
    );
};
