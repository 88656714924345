import { ReactNode, createContext } from 'react';

import { PresenterStore } from './presenter-store';

export const PresenterStoreContext = createContext({} as PresenterStore);

type TPresenterStoreProviderProps = {
  children: ReactNode;
  store: PresenterStore;
}

export const PresenterStoreProvider: React.FC<TPresenterStoreProviderProps> = ({ children, store }) => (
  <PresenterStoreContext.Provider value={store}>
    {children}
  </PresenterStoreContext.Provider>
);
