import { ReactElement } from 'react'
import { Box, Button, Flex, Heading, Link, Stack, Text } from '@chakra-ui/react'

interface ICardProps {
    heading: string;
    description: string;
    icon: ReactElement;
    href: string;
}

export const AppCard = ({ heading, description, icon, href }: ICardProps) => (
    <Box
        maxW={{ base: 'full', md: '300px' }}
        w="full"
        borderWidth="1px"
        borderRadius="lg"
        overflow="hidden"
        p={5}>
        <Stack align="start" spacing={2}>
            <Flex
                w={16}
                h={16}
                align="center"
                justify="center"
                color="white"
                rounded="full"
                bg="white">
                {icon}
            </Flex>
            <Box mt={2}>
                <Heading size="md">{heading}</Heading>
                <Text mt={1} fontSize="sm">
                    {description}
                </Text>
            </Box>
            <Link href={href}>
                <Button variant="link" colorScheme="blue" size="sm">
                    Перейти
                </Button>
            </Link>
        </Stack>
    </Box>
)
