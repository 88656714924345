import React from 'react';

import { DocsBlockView } from './docs-block.view';
import { useDocumentCards } from './docs-block.hooks';
import { observer } from 'mobx-react';

export const DocsBlock: React.FC = observer(() => {
  const { cards, addCard } = useDocumentCards();

  return <DocsBlockView documentCards={cards} addCard={addCard}/>;
})

DocsBlock.displayName = 'DocsBlockContainer';
