import { CreatorQuestionTypeEnum } from '../../../constants'
import type { Conclusion, MultivarQuestion, TextInput } from '../../../types';

const startQuestionData: MultivarQuestion = {
    id: 1,
    type: CreatorQuestionTypeEnum.MULTIVAR_BUTTON,
    title: '',
    description: '',
    fileHash: null,
    fileName: null,
    tag: `tag_${1}`,
    answers: [{ id: 0, text: '' }],
    isStart: true,
};

export function getMultivarQuestionDefaults(questionId: number): MultivarQuestion {
    return {
        id: questionId,
        type: CreatorQuestionTypeEnum.MULTIVAR_BUTTON,
        title: '',
        description: '',
        tag: `tag_${questionId}`,
        fileHash: null,
        fileName: null,
        answers: [{ id: 0, text: '' }],
        isStart: false,
    }
}

export function getTextIpuntDefaults(questionId: number): TextInput {
    return {
        id: questionId,
        type: CreatorQuestionTypeEnum.TEXT_INPUT,
        title: '',
        description: '',
        tag: `tag_${questionId}`,
        fileHash: null,
        fileName: null,
        isStart: false,
    }
}

export function getConclusionDefaults(questionId: number): Conclusion {
    return {
        id: questionId,
        type: CreatorQuestionTypeEnum.CONCLUSION,
        title: '',
        description: '',
        tag: `tag_${questionId}`,
        fileHash: null,
        fileName: null,
        isStart: false,
    }
}

export const defaultCreatorData = {
    drawflow: {
        Home: {
            data: {
                '1': {
                    name: CreatorQuestionTypeEnum.MULTIVAR_BUTTON,
                    id: 1,
                    pos_x: 420,
                    pos_y: 160,
                    class: 'multivar-question',
                    data: startQuestionData,
                    html: '',
                    typenode: false,
                    inputs: {},
                    outputs: {
                        output_1: { connections: [] },
                    },
                }
            }
        },
    }
};

export const connRenderPlaceholder = () => {};

export const nodeDefaults: [{}, string, boolean] = [
    {}, '', false
];
