import { MaterialReactTable, useMaterialReactTable, type MRT_TableOptions, MRT_TableInstance } from 'material-react-table';
import { useCreateQuiz, useDeleteQuiz, useListQuizzes, useTableColumns, useUpdateQuiz, useValidationErrors, useQuizCreatorNavigate, useCreateTrigger, useQuizId } from './hooks';
import { CreateQuizButton, QuizActions } from './components';
import { validateQuizRow } from './validation';
import type { TQuiz } from './types';
import './index.css';

type QuizzesTableProps = {
  closeModal: () => void;
}

export const QuizzesTable = ({closeModal}: QuizzesTableProps) => {
  const { validationErrors, setValidationErrors } = useValidationErrors();
  const columns = useTableColumns(validationErrors, setValidationErrors);
  const { fetchedQuizzes, isLoadingQuizzesError, isFetchingQuizzes, isLoadingQuizzes } = useListQuizzes();
  const { createQuiz, isCreatingQuiz } = useCreateQuiz();
  const { updateQuiz, isUpdatingQuiz } = useUpdateQuiz();
  const { deleteQuiz, isDeletingQuiz } = useDeleteQuiz();
  const navigateToQuiz = useQuizCreatorNavigate();
  const quizId = useQuizId();

  const handleQuizOpen = (quizId: number) => {
    closeModal();
    navigateToQuiz(quizId);
  }

  const handleStartQuizCreation = (table: MRT_TableInstance<TQuiz>) =>{
    if (!table.getState().creatingRow) {
      table.setCreatingRow(true)
    }
  }

  const handleCreateQuiz: MRT_TableOptions<TQuiz>['onCreatingRowSave'] = async ({ values, table }) => {
    const newValidationErrors = validateQuizRow(values);
    if (Object.values(newValidationErrors).some((error) => error)) {
      setValidationErrors(newValidationErrors);
      return;
    }
    setValidationErrors({});
    await createQuiz(values);
    table.setCreatingRow(null);
  };

  const handleSaveQuiz: MRT_TableOptions<TQuiz>['onEditingRowSave'] = async ({ values, table }) => {
    const newValidationErrors = validateQuizRow(values);
    if (Object.values(newValidationErrors).some((error) => error)) {
      setValidationErrors(newValidationErrors);
      return;
    }
    setValidationErrors({});
    await updateQuiz(values);
    table.setEditingRow(null);
  };

  const table = useMaterialReactTable({
    columns,
    data: fetchedQuizzes,
    createDisplayMode: 'row',
    editDisplayMode: 'row',
    enableEditing: true,
    muiTablePaperProps: { elevation: 0 },
    muiPaginationProps: { showRowsPerPage: false },
    muiToolbarAlertBannerProps: isLoadingQuizzesError
      ? { color: 'error', children: 'Ошибка загрузки опросников' }
      : undefined,
    muiTableContainerProps: { sx: { minHeight: '500px' } },
    positionActionsColumn: 'last',
    layoutMode: 'grid-no-grow',
    displayColumnDefOptions: {
      'mrt-row-actions': {
        header: 'Действия',
        size: 300,
      },
    },
    muiLinearProgressProps: ({ isTopToolbar }) => ({ sx: { display: isTopToolbar ? 'block' : 'none' } }),
    onCreatingRowCancel: () => setValidationErrors({}),
    onCreatingRowSave: handleCreateQuiz,
    onEditingRowCancel: () => setValidationErrors({}),
    onEditingRowSave: handleSaveQuiz,
    renderRowActions: ({ row, table }) => <QuizActions 
      quiz={row}
      openedQuizId={quizId}
      setEditiongQuiz={table.setEditingRow} 
      openQuizInCreator={handleQuizOpen} 
      deleteQuiz={deleteQuiz} />,
    renderTopToolbarCustomActions: ({ table }) => <CreateQuizButton enableCreatingQuiz={() => handleStartQuizCreation(table)} />,
    state: {
      isLoading: isLoadingQuizzes,
      isSaving: isCreatingQuiz || isUpdatingQuiz || isDeletingQuiz,
      showAlertBanner: isLoadingQuizzesError,
      showProgressBars: isFetchingQuizzes,
    },
  });

  useCreateTrigger(() => table.setCreatingRow(true));

  return <MaterialReactTable table={table} />;
};
