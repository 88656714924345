import { useEffect, useState } from 'react';
import Drawflow, { DrawflowExport } from 'drawflow';
import { downloadAsJson, uploadAsJson } from './files';
import { useStore } from '../store';
import type { CreatorQuestionType, Positions } from '../../../types';
import { getQuizIdFromPath } from '../../../common/hooks/get-quiz-id-from-path';

export const useCreator = () => {
  const [mobileQuestionType, setMobileQuestionType] = useState<CreatorQuestionType | null>(null)
  const [mobileLastMove, setMobileLastMove] = useState<TouchEvent | null>(null);
  const [isLocked, setIsLocked] = useState(false);
  const store = useStore();
  const quizId = getQuizIdFromPath();
  useEffect(() => store.init(quizId), []);

  function addNode(questionType: string, xCoordinate: number, yCoordinate: number) {
    if (store.editor && store.editor.editor_mode !== 'fixed') {
      const positions = getAbsolutePositionsForNode(store.editor, xCoordinate, yCoordinate);
      store.addQuestion(questionType, positions);
    }
  }

  function getAbsolutePositionsForNode(editor: Drawflow, xCoordinate: number, yCoordinate: number): Positions {
    const xPos =
      xCoordinate * (editor.precanvas.clientWidth / (editor.precanvas.clientWidth * editor.zoom)) -
      editor.precanvas.getBoundingClientRect().x * (editor.precanvas.clientWidth / (editor.precanvas.clientWidth * editor.zoom));

    const yPos =
      yCoordinate * (editor.precanvas.clientHeight / (editor.precanvas.clientHeight * editor.zoom)) -
      editor.precanvas.getBoundingClientRect().y * (editor.precanvas.clientHeight / (editor.precanvas.clientHeight * editor.zoom));


    return { xPos, yPos };
  }

  function toggleLock() {
    if (store.editor) {
      if (isLocked) {
        store.editor.editor_mode = 'edit';
        setIsLocked(false);
      } else {
        store.editor.editor_mode = 'fixed';
        setIsLocked(true);
      }
    }
  }

  function handleUpload(file: File) {
    uploadAsJson(file, (quizData: DrawflowExport) => store.restoreCreatorState(quizData));
  }

  function handleDownload(e: React.MouseEvent) {
    if (store.editor) {
      const quiz = store.editor.export();
      downloadAsJson(e, quiz);
    }
  }

  function clear() {
    store.editor?.clearModuleSelected();
  }

  function setBackgroundImage(url: string | undefined) {
    store.setPresenterTheme({ ...store.presenterTheme, backgroundImage: url });
  }

  return {
    quizId: store.quizId,
    isLocked,
    toggleLock,
    addNode,
    setMobileQuestionType,
    mobileQuestionType,
    mobileLastMove,
    positionMobile: (e) => setMobileLastMove(e),
    isLoaded: Boolean(store.editor),
    handleUpload,
    handleDownload,
    clear,
    export: () => store.editor?.export(),
    editorMode: store.editor?.editor_mode,
    zoomOut: () => store.editor?.zoom_out(),
    zoomIn: () => store.editor?.zoom_in(),
    zoomReset: () => store.editor?.zoom_reset(),
    theme: store.presenterTheme,
    setBackgroundImage,
  }
}
