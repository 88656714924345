import React, { ChangeEvent } from 'react';
import { Form } from 'react-admin';
import {
  Card,
  CardContent,
  IconButton,
  TextField,
  Typography
} from '@mui/material';
import { DeleteOutlined } from '@mui/icons-material'
import { styled } from '@linaria/react';

import type { TDocumentCard, TDocumentRole } from '../../../../../../types';
import { FormSwitchButton } from '../form-switch-button';
import { DnDFileInput } from '../dnd-file-input/dnd-file-input';

type TDocumentCardViewProps = TDocumentCard & {
  onSwitchButtonToggle: () => void;
  onTextContentChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onNameChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onDeleteClick: () => void;
}

export const DocumentCardView: React.FC<TDocumentCardViewProps> = props => {
  const {
    role,
    onSwitchButtonToggle,
    onTextContentChange,
    onDeleteClick,
    content,
    isFile,
    id
  } = props;

  return (
    <Form>
      <Card sx={getCardStyle(role)}>
        <CardContent sx={cardContentStyle}>
          <CardHeader>
            <Typography variant='h6'>
              {displayCardRoles[role]}
            </Typography>
            {role !== 'main' && (
              <IconButton onClick={onDeleteClick}>
                <DeleteOutlined />
              </IconButton>
            )}
          </CardHeader>
          <FormSwitchButton text="Как файл .docx" isChecked={isFile} onChange={onSwitchButtonToggle} />
          {isFile ? (
            <DnDFileInput
              documentCardId={id}
              file={content as unknown as File}
            />
          ) : (
            <TextField
              label="Текст документа"
              value={content}
              onChange={onTextContentChange}
              variant="filled"
              size="small"
              margin="dense"
              fullWidth
              multiline
              rows={5}
            />
          )}
        </CardContent>
      </Card>
    </Form>
  )
}

DocumentCardView.displayName = 'DocumentCardView';

const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const getCardStyle = (docRoleName: TDocumentRole) => {
  // if (docRoleName === 'main') {
  //   return cardStyle;
  // }

  return {
    ...cardStyle,
    backgroundColor: 'var(--accent-block-color)'
  }
}

const cardStyle = {
  width: 360,
  minHeight: 320,
};

const cardContentStyle = {
  display: 'flex',
  flexDirection: 'column',
  gap: 1
};

const displayCardRoles = {
  main: 'Основной документ',
  supplementary: 'Дополнительный документ'
};
