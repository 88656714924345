import { CreatorQuestionTypeEnum, PresenterQuestionTypeEnum, MULTIVAR_ANSWERS_FIELD } from '../constants';
import type { CreatorNode } from './types/creator-types';
import type { PresenterQuestion } from './types/presenter-types';
import type { CreatorQuestionType, PresenterQuestionType } from '../types';

export function fromCreatorNodesToPresenterQuestions(creatorNodes: CreatorNode[]): PresenterQuestion[] {
    const answersMap = getMultivarQuestionAnswersMap(creatorNodes);

    return creatorNodes.map(node => {
        const presenterType = getPresenterQuestionType(node.data.type);
        const visibleIf = getVisibilityExpression(node, answersMap);

        const presenterQstn: PresenterQuestion = {
            type: presenterType,
            name: String(node.id),
            title: node.data.title,
            description: node.data.description,
            tag: node.data.tag,
            fileHash: node.data.fileHash,
            visibleIf,
        };

        if (node.data.type === CreatorQuestionTypeEnum.MULTIVAR_BUTTON) {
            presenterQstn.choices = answersMap[node.id];
        }

        return presenterQstn;
    });
}

function getVisibilityExpression(currNode: CreatorNode, answersMap: AnswersMap): string {
    if (currNode.data.isStart) {
        return 'true';
    }

    const visiblityClauses = currNode.inputs.input_1.connections.map(conn => {
        const parentNodeId = parseInt(conn.node, 10);

        if (parentNodeId in answersMap) {  
            const answer = getParentNodeAnswer(conn.input, answersMap[parentNodeId]);
            return `{${parentNodeId}} = '${answer}'`;
        }

        return `{${parentNodeId}} notempty`;
    });

    return visiblityClauses.join(' or ');
}

function getParentNodeAnswer(inputValue: string, parentAnswers: string[]) {
    const parentNodeAnswerIndex = parseInt(inputValue.split('_')[1], 10) - 1;
    return parentAnswers[parentNodeAnswerIndex];
}

function getPresenterQuestionType(questionType: CreatorQuestionType): PresenterQuestionType {
    switch (questionType) {
        case CreatorQuestionTypeEnum.MULTIVAR_BUTTON:
            return PresenterQuestionTypeEnum.radiogroup;
        case CreatorQuestionTypeEnum.MULTIVAR_DROPDOWN:
            return PresenterQuestionTypeEnum.dropdown;
        case CreatorQuestionTypeEnum.TEXT_INPUT:
            return PresenterQuestionTypeEnum.text;
        default:
            return PresenterQuestionTypeEnum.conclusion;
    }
}

function getMultivarQuestionAnswersMap(creatorNodes: CreatorNode[]): AnswersMap {
    const answersMap = {};

    for (const node of creatorNodes) {

        if (MULTIVAR_ANSWERS_FIELD in node.data) {
            const answers = node.data.answers;
            const nodeId = node.data.id;

            if (answers) {
                const answerTexts = answers.map(({ text }) => text);
                answersMap[nodeId] = answerTexts;
            }
        }
    }

    return answersMap;
}

type AnswersMap = Record<number, string[]>;
