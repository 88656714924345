import { Button, IconButton } from '@chakra-ui/react';
import { GrTest } from 'react-icons/gr';

type TToTestButtonProps = {
    switchToTest: () => void;
    isMobile: boolean;
}

export const ToTestButton = ({ switchToTest, isMobile }: TToTestButtonProps) => (
    isMobile ? (
        <IconButton
            onClick={switchToTest}
            icon={<GrTest size={24} />}
            colorScheme="blue"
            pointerEvents="auto"
            aria-label="to-test"
        />
    ) : (
        <Button
            onClick={switchToTest}
            colorScheme="blue"
            variant="solid">
            Тестирование
        </Button>
    )
);
