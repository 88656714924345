import { CustomWidgetCollection, Serializer } from 'survey-core';
import { CONCLUSTION_SURVEY_TYPE } from '../../../constants';
import { FinalQuestion } from '../custom-questions';

export const addCustomWidgets = () => CustomWidgetCollection.Instance.add({
    isFit: (question) => question.getType() === CONCLUSTION_SURVEY_TYPE,
    init: () => Serializer.addClass(CONCLUSTION_SURVEY_TYPE, [], undefined, 'empty'),
    render: (question) => {
        const handleFinish = () => question.getSurvey().doComplete();

        return <FinalQuestion
            title={question.jsonObj.title}
            description={question.jsonObj.description}
            surveyModel={question.surveyValue}
            onFinish={handleFinish}
            fileHash={question.fileHash} />
    }
});

export const addCustomProperties = () => {
    Serializer.addProperty('question', {
        name: 'tag',
        type: 'text',
        category: 'general',
        default: null
    });
    Serializer.addProperty('question', {
        name: 'fileHash',
        type: 'text',
        category: 'general',
        default: null
    });
}

// В случае необходимости создания кнопки 'Далее' для текстовых полей (не доделано)
/* 
useEffect(() => {
    const nextButton = survey.addNavigationItem({
        id: 'customNext',
        title: 'Далее',
        visible: false,
        action: survey.render
    });

    survey.onAfterRenderQuestion.add((s, options) => {
        const currentQuestion = options.question;
        const questionType = currentQuestion.getType();
        if (currentQuestion && questionType === 'text') {
            nextButton.visible = true;
            nextButton.enabled = true;
        } else {
            nextButton.visible = false;
            nextButton.enabled = false;
        }
    });
}, []);
 */
