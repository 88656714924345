import { ACCESS_TOKEN_KEY, USERNAME_KEY } from './constants';

export class AuthStorage {
  static getUsername() {
    return localStorage.getItem(USERNAME_KEY);
  }

  static getAccessToken() {
    return localStorage.getItem(ACCESS_TOKEN_KEY);
  }

  static hasAuthData() {
    return Boolean(AuthStorage.getAccessToken() && AuthStorage.getUsername());
  }
}
