import { ChevronDownIcon } from '@chakra-ui/icons';
import { Button, IconButton, MenuButton } from '@chakra-ui/react';
import { PiTreeStructure } from 'react-icons/pi';

export const QuizOptionsButton = ({ isMobile }: { isMobile: boolean }) => (
    isMobile ? (
        <MenuButton as={IconButton} icon={<PiTreeStructure size={24}/>} />
    ) : (
        <MenuButton as={Button} rightIcon={<ChevronDownIcon />}>
            Опросник
        </MenuButton>
    )
);
