import React, { ReactNode } from 'react';

import { InspectionStore } from './inspection-store';

export const InspectionStoreContext = React.createContext({} as InspectionStore);

type TInspectionStoreProviderProps = {
  children: ReactNode;
  store: InspectionStore;
}

export const InspectionStoreProvider: React.FC<TInspectionStoreProviderProps> = ({ children, store }) => (
  <InspectionStoreContext.Provider value={store}>
    {children}
  </InspectionStoreContext.Provider>
);
