import { useContext } from 'react';

import { InspectionStore, InspectionStoreContext } from '../store';

export function useDocumentCards() {
  const store = useContext<InspectionStore>(InspectionStoreContext);

  return {
    cards: store.documentCards,
    addCard: () => store.addNewDocumentCard()
  };
}
