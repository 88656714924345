import React from 'react';
import {
    Drawer, DrawerContent, DrawerCloseButton, DrawerHeader, DrawerBody, List,
    ListItem, Tag, TagLeftIcon, TagLabel, Switch, FormControl, FormLabel
} from '@chakra-ui/react';
import { DragHandleIcon } from '@chakra-ui/icons';
import { css, cx } from '@linaria/core';
import { useSidenav } from './hooks';
import type { CardToPickValues } from '../../../../types';
import { useStore } from '../../store';
import { observer } from 'mobx-react';
import { LinkSaver } from '../link-saver';

type SidenavProps = {
    cardToPickValues: CardToPickValues;
    imageUrl?: string;
    updateImageURL: (url: string) => void;
    drag: (e) => void;
    touchStart: (e) => void;
    touchEnd: (e) => void;
    touchMove: (e) => void;
}

export const SideInstruments: React.FC<SidenavProps> = observer(({
    cardToPickValues, drag, touchStart, touchEnd, touchMove, imageUrl, updateImageURL
}) => {
    const { isOpen, onToggle } = useSidenav();
    const store = useStore();

    function handleTagsVisibilityChange() {
        store.toggleTagsVisibility();
    }

    return (
        <Drawer
            placement="right"
            variant='alwaysOpen'
            onClose={onToggle}
            isOpen={isOpen}
            blockScrollOnMount={false}
            trapFocus={false}
            closeOnOverlayClick={false}
        >
            <DrawerContent style={{top: "96px"}}>
                <DrawerCloseButton />
                <DrawerHeader border="none">Вопросы</DrawerHeader>
                <DrawerBody display="flex" flexDirection="column" justifyContent="space-between">
                    <List spacing={2}>
                        {cardToPickValues.map(({ text, questionType }) => (
                            <ListItem key={questionType}>
                                <Tag
                                    size="lg"
                                    key={questionType}
                                    variant="subtle"
                                    className={cx('pickable-card', tagClassName)}
                                    onDragStart={drag}
                                    onTouchStart={touchStart}
                                    onTouchEnd={touchEnd}
                                    onTouchMove={touchMove}
                                    data-node={questionType}
                                    height={12}
                                    width="100%"
                                    draggable
                                >
                                    <TagLeftIcon boxSize='12px' as={DragHandleIcon} />
                                    <TagLabel className={tagLabelClassName}>{text}</TagLabel>
                                </Tag>
                            </ListItem>
                        ))}
                    </List>
                    <List display="flex" flexDirection="column" gap={8} mb={8}>
                        <FormControl display="flex" alignItems="center">
                            <FormLabel htmlFor="show-tags" mb="0">
                                Теги для шаблонов
                            </FormLabel>
                            <Switch isChecked={store.isTagsVisible} onChange={handleTagsVisibilityChange} id="show-tags" />
                        </FormControl>
                        <LinkSaver 
                            name="Фоновое изображение"
                            defaultUrl={imageUrl} 
                            updateUrl={updateImageURL} />
                    </List>
                </DrawerBody>
            </DrawerContent>
        </Drawer>
    );
});

const tagClassName = css`
  cursor: move;
  user-select: none;
`;

const tagLabelClassName = css`
  -webkit-line-clamp: none!important;
  --chakra-line-clamp: 0!important;
`;
