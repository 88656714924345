import { Model } from 'survey-core';
import { addCustomWidgets, addCustomProperties } from './survey-customization';
import { Api } from '../../../api';
import { getQuizIdFromPath } from '../../quiz-creator/hooks';
import { makeObservable, observable, action, configure } from 'mobx';
import { getQuizHashFromPath } from '../../../common/hooks';

addCustomWidgets();
addCustomProperties();
configure({ safeDescriptors: false });

export class PresenterStore {
  quizId: number | undefined = undefined;
  answeredIds: string[] = [];
  model = new Model();

  constructor(isDevMode: boolean) {
    makeObservable(this, {
      quizId: observable,
      init: action,
    });

    this.init(isDevMode);
  }

  async init(isDevMode: boolean) {
    let theme, questions;

    if (isDevMode) {
      const quizId = getQuizIdFromPath();
      if (quizId) {
        const { presenterContent, presenterTheme } = await Api.getQuizForPresenterTest(quizId);
        theme = presenterTheme;
        questions = presenterContent.questions;
        this.addBackToCreatorButton(quizId);
      }
    } else {
      const quizHash = getQuizHashFromPath();
      if (quizHash) {
        const { presenterContent, presenterTheme } = await Api.getQuizForPresenter(quizHash);
        theme = presenterTheme;
        questions = presenterContent.questions;
      }
    }

    this.model.setJsonObject({ questions, ...surveyParams })
    this.model.applyTheme(theme);
    this.trackAnsweredQuestions();
    this.addAutoScroll();
    this.addBackButton();
  }

  private addQuestionId(questionId: string) {
    this.answeredIds.push(questionId);
  }

  private hasNotQuestionId(questionId: string) {
    return !this.answeredIds.includes(questionId);
  }

  private getPrevQuestionId() {
    return this.answeredIds[this.answeredIds.length - 2];
  }

  private addBackButton() {
    const prevQstnBtn = this.model.addNavigationItem({
      action: () => {
        this.undoLastAnsweredQuestion()
        this.removeLastQuestionId();
      },
      ...toPrevQuestionButton
    });

    this.model.onQuestionVisibleChanged.add((s, { question: { name: questionId, isVisible } }) => {
      if (isVisible && this.hasNotQuestionId(questionId)) {
        this.addQuestionId(questionId);
        prevQstnBtn.visible = true;
      } else if (this.answeredIds[1] == questionId) {
        prevQstnBtn.visible = false;
      }
    });
  }

  private undoLastAnsweredQuestion() {
    const updatedData = this.model.data;
    const prevQuestionId = this.getPrevQuestionId();
    delete updatedData[prevQuestionId]
    this.model.data = updatedData;
  }

  private removeLastQuestionId() {
    this.answeredIds = this.answeredIds.slice(0, -1);
  }

  private trackAnsweredQuestions() {
    this.model.onAfterRenderPage.add(() => {
      const questionId = this.model.getAllQuestions()[0].name;
      this.addQuestionId(questionId)
    });
  }

  private addBackToCreatorButton(quizId: number) {
    this.model.addNavigationItem({
      action: () => window.location.pathname = `/quiz-creator/${quizId}`,
      ...toCreatorButton
    });
  }

  private addAutoScroll() {
    this.model.onAfterRenderQuestion.add(() => {
      const root = document.getElementById('survey');
      const surveyInsides = document.querySelector<HTMLElement>('.sd-root-modern__wrapper');
      root?.scrollTo({
        top: surveyInsides?.offsetHeight,
        behavior: 'smooth'
      });
    });
  }
}

const toPrevQuestionButton = {
  title: 'Назад',
  innerCss: 'nav-btn back-to-prev-qustion-btn',
  visibleIndex: 1,
  visible: false,
};

const toCreatorButton = {
  title: 'В конструктор',
  innerCss: 'nav-btn back-to-creator-nav-btn', // Стили, схожие с кнопкой "В опросник" из creator-а
  visibleIndex: 2
};

const surveyParams = {
  showNavigationButtons: 'bottom',
  clearInvisibleValues: 'onHidden',
  showQuestionNumbers: false,
  completedHtml: '<h6>Опросник завершен.<br/>Для повторного прохождения перезагрузите страницу.</h6>',
  textUpdateMode: 'onTyping',
  locale: 'ru',
};
