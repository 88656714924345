import React from 'react';
import { css } from '@linaria/core';
import { IconButton } from '@mui/material';
import { Add } from '@mui/icons-material';

import { DocumentCard } from './components/components';
import type { TDocumentCards } from '../../../types';

type DocsBlockViewProps = {
  documentCards: TDocumentCards;
  addCard: () => void;
}

export const DocsBlockView: React.FC<DocsBlockViewProps> = ({ documentCards, addCard }) => (
  <div className={docksBlock}>
    {documentCards.map((cardContent) =>
      <DocumentCard key={cardContent.id} {...cardContent}/>
    )}
    <IconButton onClick={addCard} size="large" color="primary" sx={buttonStyle}>
      <Add fontSize="inherit"/>
    </IconButton>
  </div>
);

DocsBlockView.displayName = 'DocsBlockView';

const docksBlock = css`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: var(--space-300);
  justify-content: start;
`;

const buttonStyle = {
  height: 40,
  width: 40,
  alignSelf: 'center',
  marginLeft: -2
};
