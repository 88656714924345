import { Button } from '@mui/material';
import { Add } from '@mui/icons-material';

export const CreateQuizButton = ({ enableCreatingQuiz }: { enableCreatingQuiz: () => void }) => (
    <Button
        startIcon={<Add />}
        variant="outlined"
        onClick={() => enableCreatingQuiz()}>
        Создать опросник
    </Button>
);
