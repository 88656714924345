import { useEffect, useState } from 'react'
import type { BaseQuestionDefaults } from '../../../../../types';
import { useStore } from '../../../store';

export const useTextInput = (questionId: number, connRerender: () => void, defaults?: BaseQuestionDefaults) => {
    let defaultTitle = '';
    let defaultDescription = '';
    let defaultTag = `tag_${questionId}`;

    if (defaults) {
        defaultTitle = defaults.title;
        defaultDescription = defaults.description;
        defaultTag = defaults.tag;
    }

    const [title, setTitle] = useState(defaultTitle);
    const [description, setDescription] = useState(defaultDescription);
    const [tag, setTag] = useState(defaultTag);
    const store = useStore();
    useEffect(connRerender, [store.isTagsVisible]);

    function updateTitle({ target: { value: title } }: React.ChangeEvent<HTMLInputElement>) {
        setTitle(title);
        store.updateNodeData(questionId, { title });
    }

    function updateDescription({ target: { value: description } }: React.ChangeEvent<HTMLTextAreaElement>) {
        setDescription(description);
        store.updateNodeData(questionId, { description });
    }

    function updateTag({ target: { value: tag } }: React.ChangeEvent<HTMLInputElement>) {
        setTag(tag);
        store.updateNodeData(questionId, { tag });
    }

    return {
        title,
        description,
        tag,
        isTagVisible: store.isTagsVisible,
        updateTitle,
        updateDescription,
        updateTag,
    }
}
