import { useContext } from 'react';

import { InspectionStore, InspectionStoreContext } from '../../../../store';

export function useFileInput(cardId: string) {
  const store = useContext<InspectionStore>(InspectionStoreContext);

  return {
    addFile: (file: File) => {
      store.addFileToDocumentCard(cardId, file);
    },
    removeFile: (cardId: string) => {
      store.removeDocumentCardFile(cardId);
    }
  }
}
