import { ReactNode, createContext } from 'react';

import { CreatorStore } from './creator-store';

export const CreatorStoreContext = createContext({} as CreatorStore);

type TCreatorStoreProviderProps = {
  children: ReactNode;
  store: CreatorStore;
}

export const CreatorStoreProvider: React.FC<TCreatorStoreProviderProps> = ({ children, store }) => (
  <CreatorStoreContext.Provider value={store}>
    {children}
  </CreatorStoreContext.Provider>
);
