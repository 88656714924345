import 'drawflow/dist/drawflow.min.css';
import './index.css';
import { Creator } from './creator';
import { creatorStore, CreatorStoreProvider } from './store';

export const QuizCreator = () => {
  return (
    <CreatorStoreProvider store={creatorStore}>
      <Creator />
    </CreatorStoreProvider>
  );
}