import React from 'react';

import { RuleBlockView } from './rule-block.view';
import { useRules } from './rule-block.hooks';
import { observer } from 'mobx-react';

export const RuleBlock: React.FC = observer(() => {
  const { isLoading, onRuleCheckClick, rules } = useRules();

  if (isLoading) {
    return <div>Loading...</div>;
  }
  return (
    <RuleBlockView
      isLoading={isLoading}
      onRuleCheckClick={onRuleCheckClick}
      rules={rules}
    />
  );
});
