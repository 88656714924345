import React from 'react';
import { Box } from '@chakra-ui/react';

type TBorderedBoxProps = {
  children?: React.ReactNode;
}

export const BorderedBox: React.FC<TBorderedBoxProps> = ({ children }) => (
  <Box sx={borderedBoxStyles}>
    {children}
  </Box>
);

const borderedBoxStyles = {
  border: '1px solid',
  borderColor: 'var(--block-border-color)',
  borderRadius: 'var(--rounding-l)',
  padding: 'var(--space-200)'
}
