export function getQuizIdFromPath() {
    const path = window.location.pathname;
    const quizIdRegex = /\/(\d+)/;
    const match = path.match(quizIdRegex);
  
    if (match && match[1]) {
      const id = parseInt(match[1], 10);
      if (!Number.isNaN(id)) {
        return id;
      }
    }
  
    return undefined;
}
