
import { useQuery } from '@tanstack/react-query';
import { Api } from '../../../../../../api';
import type { TQuizzes } from '../types';
import { accessVariantsToValue } from './use-table-columns';

export function useListQuizzes() {
    const result = useQuery<TQuizzes>({
        queryKey: ['quizzes'],
        queryFn: async () => {
            const quizzes = await Api.getQuizzes()
            return quizzes.map(quiz => ({...quiz, access: accessVariantsToValue[quiz.access]}))
        },
        refetchOnWindowFocus: false,
    });

    return {
        fetchedQuizzes: result.data || [],
        isLoadingQuizzesError: result.isError,
        isFetchingQuizzes: result.isFetching,
        isLoadingQuizzes: result.isLoading,
    }
}
