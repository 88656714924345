import type { TRules, TAnalysisResult, TDocumentCardDto } from '../types';
import { AuthStorage } from '../auth';
import { parseDocx } from '../utils';
import type { TQuiz, TQuizContentsSave, TQuizCreate, TQuizForCreator, TQuizForPresenter, TQuizzes } from '../pages/quiz-creator/components/controls-top-panel/quizzes-table-modal/types';
import { httpClient } from './http-client';
import axios, { AxiosError } from 'axios';

export class Api {
  static fetchAvailableRules() {
    return httpClient
      .get<TRules>('/rules/available', Api.getRequestConfig())
      .then(({ data }) => ({ data }))
      .catch((error) => ({ error }));
  }

  static async submitForInspection(documentCards: TDocumentCardDto[], ruleIds: string[]) {
    const documents: unknown[] = [];
    for (const card of documentCards) {
      let paragraphs;
      if (typeof card.content === 'string') {
        paragraphs = card.content.split(/\r?\n/);
      } else {
        paragraphs = await parseDocx(card.content);
      }
      documents.push({
        id: card.id,
        name: card.name,
        role: card.role,
        paragraphs
      });
    }

    const payload = {
      documents,
      ruleIds,
    };

    return httpClient
      .post<TAnalysisResult>('/inspections/check', payload, Api.getRequestConfig())
      .then(({ data }) => ({ data }))
      .catch((error) => ({ error }));
  }

  static getQuizzes = () => httpClient
    .get<TQuizzes>('/quizzes/list', Api.getRequestConfig())
    .then(({ data }) => data);

  static getQuizForCreator = (id: number) => httpClient
    .get<TQuizForCreator>(`/quizzes/creator/${id}`, { ...Api.getRequestConfig() })
    .then(({ data }) => data);

  static getQuizForPresenterTest = (id: number) => httpClient
    .get<TQuizForPresenter>(`/quizzes/presenter/test/${id}`, { ...Api.getRequestConfig() })
    .then(({ data }) => data);

  static getQuizForPresenter = (quizHash: string) => httpClient
    .get<TQuizForPresenter>(`/quizzes/presenter/${quizHash}`, { ...Api.getRequestConfig(false) })
    .then(({ data }) => data);

  static saveQuizContents = ({ id, presenterContent, creatorContent, presenterTheme }: TQuizContentsSave) => httpClient
    .put<TQuiz>(`/quizzes/${id}`, { presenterContent, creatorContent, presenterTheme }, { ...Api.getRequestConfig() })
    .then(({ data }) => data);

  static createQuiz = (quiz: TQuizCreate) => httpClient
    .post<TQuiz>('/quizzes', quiz, { ...Api.getRequestConfig() })
    .then(({ data }) => data);

  static updateQuiz = (quiz: TQuiz) => httpClient
    .put<TQuiz>(`/quizzes/${quiz.id}`, quiz, { ...Api.getRequestConfig() })
    .then(({ data }) => data);

  static deleteQuiz = (id: number) => httpClient
    .delete<void>(`/quizzes/${id}`, { ...Api.getRequestConfig() })
    .then(({ data }) => data);

  static uploadFile = (file: File) => {
    const formData = new FormData();
    formData.append('file', file);

    return httpClient
      .post<{ fileHash: string }>('/files', formData, { ...Api.getFileRequestConfig() })
      .then(({ data }) => data.fileHash);
  }

  static downloadFile = async (fileHash: string) => httpClient
    .get<Buffer>(`/files/${fileHash}`, { ...Api.getRequestConfig(), responseType: 'arraybuffer' })
    .then(({ data }) => data);

  static deleteFile = (fileHash: string) => httpClient
    .delete<void>(`/files/${fileHash}`, { ...Api.getRequestConfig() })
    .then(({ data }) => data)
    .catch((err: Error | AxiosError) => {
      if (axios.isAxiosError(err)) {
        return { code: err.response?.status }
      } else {
        throw err;
      }
    });

  private static getRequestConfig(isAuth = true) {
    const headers = { 'Content-Type': 'application/json' };
    if (isAuth) {
      headers['authorization'] = `Bearer ${AuthStorage.getAccessToken()}`;
    }

    return {
      headers
    };
  }

  private static getFileRequestConfig() {
    return {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${AuthStorage.getAccessToken()}`,
      }
    };
  }
}
