import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Api } from '../../../../../../api';
import type { TQuiz } from '../types';
import { accessVariantsToDisplay } from './use-table-columns';

export function useUpdateQuiz() {
  const queryClient = useQueryClient();

  const result = useMutation({
    mutationFn: (quizData) => Api.updateQuiz({
      ...quizData, access: accessVariantsToDisplay[quizData.access]
   }),
    //client side optimistic update
    onMutate: (newQuizInfo: TQuiz) => {
      queryClient.setQueryData(['quizzes'], (prevQuizzes: any) =>
        prevQuizzes?.map((prevQuiz: TQuiz) =>
          prevQuiz.id === newQuizInfo.id ? newQuizInfo : prevQuiz,
        ),
      );
    },
    onSettled: () => queryClient.invalidateQueries({ queryKey: ['quizzes'] }), //refetch quizzes after mutation
  });

  return {
    updateQuiz: result.mutateAsync,
    isUpdatingQuiz: result.isPending,
  }
}
