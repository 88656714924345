import { type TQuiz } from './types';

export function validateQuizRow(quiz: TQuiz) {
    return {
        name: !validateRequired(quiz.name)
            ? 'Укажтие название опросника'
            : '',
        title: !validateRequired(quiz.title)
            ? 'Укажите заголовок опросника'
            : '',
        access: !validateRequired(quiz.access)
            ? 'Выберите уровень доступа к опроснику'
            : '',
    };
}

function validateRequired(value: string) {
    return !!value.length;
}
