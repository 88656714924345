import React from 'react';
import {
    Accordion, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon, Box
} from '@chakra-ui/react'

type QuestionAccodionProps = {
    children: React.ReactNode;
    buttonText: string;
    className?: string;
};

export const QuestionAccordion: React.FC<QuestionAccodionProps> = ({ children, buttonText, className }) => (
    <Accordion className={className} allowToggle>
        <AccordionItem>
            <AccordionButton>
                <Box flex='1' textAlign='left' marginLeft="16">
                    {buttonText}
                </Box>
                <AccordionIcon />
            </AccordionButton>
            <AccordionPanel pb={4}>
                {children}
            </AccordionPanel>
        </AccordionItem>
    </Accordion>
);
